import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KycFormComponent } from './kyc-form.component';



const routes: Routes = [{ path: "", component: KycFormComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KycFormRoutingModule { }
