import { Inject, Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { throwError as observableThrowError, of, throwError } from 'rxjs';
import { ServiceLocator } from "src/app/models/general/service-locator";

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public _headers: HttpHeaders;
  public http: HttpClient;
  public baseUrl!: string | undefined;
  public site_redirect_url!: string | undefined;
  public interswitchUrl!: string | undefined;
  public interswitchMacKey!: string | undefined;
  public flexsureEndpoint!: string | undefined;

    // Flexsure Api-Keys
    private prodKey = 'oiuiiusdiTUIA893208KKYUEFWEU7WQiasugsif89r73+0++==AYUEIOK?/LKOAUD124Aasswetywiugjiuyoriwhrwqur81f5ef1344a860983682&(*))*^29287ca63d5b4cf41'
    private testKey = '+WeiQSAXzqIBJnNrEtd68dam0/EgAgiwai/Wg7aEh1AqlQINnOdfZkFaa/QXnVvhvhc6Q0nQJUIL9yd42nJ005J/INnOdfZkFaa/QXnVvhvhc6Q'
    private flexsure_endpoint_prod = 'https://portal.aiicoplc.com'
    private flexsure_endpoint_test = 'https://portal-staging.aiicoplc.com'


  // Interswitch
  private interswitchUrl_test = 'https://sandbox.interswitchng.com/collections/w/pay';
  private interswitchUrl_prod = 'https://webpay.interswitchng.com/collections/w/pay';
  private site_redirect_url_prod = `${this.flexsure_endpoint_prod}/api/TokenAuth/RedirectInterswitchPayment`;
  private site_redirect_url_test = `${this.flexsure_endpoint_test}/api/TokenAuth/RedirectInterswitchPayment`;
  // "http://localhost:4200/result/interswitch";
  // "https://e-business-staging.aiicoplc.com/result/interswitch/"
  private interswitchMacKey_test = 'D3D1D05AFE42AD50818167EAC73C109168A0F108F32645C8B59E897FA930DA44F9230910DAC9E20641823799A107A02068F7BC0F4CC41D2952E249552255710F';
  private interswitchMacKey_prod = 'D3D1D05AFE42AD50818167EAC73C109168A0F108F32645C8B59E897FA930DA44F9230910DAC9E20641823799A107A02068F7BC0F4CC41D2952E249552255710F';

  public appUrl = "https://flexureadmin-ebiz-staging.azurewebsites.net";
  private prodEvrn!: boolean | undefined;

  // Base Endpoint Url
  private prodUrl = "https://portal.aiicoplc.com/api/services/app";
  private testUrl = "https://portal-staging.aiicoplc.com/api/services/app";
  //private testUrl = "http://localhost:53262/api/services/app";


  constructor() {
    // this.http = _http;
    this.http = ServiceLocator.injector.get(HttpClient);

    // changing to Prod or Test
    this.prodEvrn = true;

    this.baseUrl = this.prodEvrn ? this.prodUrl : this.testUrl;
    this.flexsureEndpoint = this.prodEvrn ? this.flexsure_endpoint_prod : this.flexsure_endpoint_test;
    this.site_redirect_url = this.prodEvrn ? this.site_redirect_url_prod : this.site_redirect_url_test;
    this.interswitchUrl = this.prodEvrn ? this.interswitchUrl_prod : this.interswitchUrl_test;
    this.interswitchMacKey = this.prodEvrn ? this.interswitchMacKey_prod : this.interswitchMacKey_test;
  }

  public _serverError(err: any) {
    // return of(null);
    return throwError(err)
  }

  get headers(): HttpHeaders {
    this._headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-ApiKey':  this.prodEvrn === true ? this.prodKey : this.testKey,
      'Access-Control-Allow-Headers': 'Content-Type',
    });

    return this._headers;
  }

  getApiBaseUrl(): string {
    return this.flexsureEndpoint;
  }

  set headers(newHeader: HttpHeaders) {
    this._headers = newHeader;
  }
}
