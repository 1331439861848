import { AfterViewInit, Component, EventEmitter, Inject, NgZone, OnInit, Output, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { RenewalService } from 'src/app/services/renewal.service';




@Component({
  selector: 'app-loanlink',
  templateUrl: './loanlink.component.html',
  styleUrls: ['./loanlink.component.scss']
})
export class LoanlinkComponent implements OnInit{
  @Output('action') actionEmitter: EventEmitter<any> = new EventEmitter<any>();
  loading!: boolean | undefined;
  errorStatus$ = new BehaviorSubject('');
  errorMessage$ = new BehaviorSubject('');
  error!: boolean | undefined;
  extraInfo$: BehaviorSubject<any>;
  transactionRef: string;
  outputMessage$ = new BehaviorSubject('');
  outputMessageDescription$ = new BehaviorSubject('');
  polNumber:string;


  constructor(
    private route: ActivatedRoute,
    private renewalService:RenewalService,
    public router: Router,
  ) {  }

  ngOnInit() {
    this.polNumber = this.route.snapshot.queryParamMap.get('p');
    if(this.polNumber !==''||this.polNumber.length > 0) {
      this.validatePolicyNumber(this.polNumber);

    }else{
      this.loading=false;
      this.error=true;
      this.outputMessage$.next("Failed");
      this.outputMessageDescription$.next('Invalid Policy Number');
    }

   
  }



  validatePolicyNumber(value){
    this.loading=true;
    this.renewalService.GetLoanListRepaymentDetails(value).subscribe(res => {
      if (res) {
        this.renewalService.renewalDetails = res.result;
        if(res.result.loanList.length > 0) {
        this.renewalService.GetLoanRepaymentDetailsWithRef(value, res.result.loanList[0].id).subscribe(res_ => {
          if (res_) {

            this.renewalService.renewalDetails = res_.result;

            if(this.renewalService.renewalDetails.isSuccessful){
              this.renewalService.renewalDetails = res_.result;
              this.renewalService.renewalDetails["isLoanRepayment"] = true;
              this.renewalService.renewalDetails["isLifeRenewal"] = false;
              this.renewalService.renewalDetails["loanId"] = res.result.loanList[0].id;
              this.error=false;
              this.loading=false;
              this.router.navigate(['/policy-renewal']);

            } else {
              this.outputMessage$.next('Policy Not found');
              this.outputMessageDescription$.next('Kindly check your policy number, Thanks');
              this.error=true;
              this.loading=false;

            }



          }
          this.loading=false;

        }), error => {
          this.outputMessage$.next(`${error.status}`);
          this.outputMessageDescription$.next(error.statusText);
          this.error=true;
          this.loading=false;
        }
      }
      else {
        this.outputMessage$.next('Invalid Policy number');
        this.error=true;
        this.loading=false;
      }
    }

    else {
      this.loading=false;
    }

    }), error => {
      this.outputMessage$.next(`${error.status}`);
      this.outputMessageDescription$.next(error.statusText);
      this.error=true;
      this.loading=false;
    }
  
  
  
  
  }
    

}
