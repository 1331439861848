export class InsuranceVm {

  //motor
  bodyType: string;
  branchId: string;
  chasisNo: string;
  clientId: string;
  color: string;
  cubicCap: string;
  dateOfBirth: Date;
  dlIssueDate: string;
  dlNo: string;
  engineNo: string;
  firstName: string;
  lastName: string;
  make: string;
  model: string;
  premiumAmount: any;
  productId: string;
  pryEmail: string;
  regNo: string;
  smsTel: string;
  subclassSectCovtypeId: string;
  vehicleAmount: any;
  wefDt: string;
  wetDt: string;
  yrManft: string;

  // travel
  country: string;
  countryId: string;
  excess: number;
  isActive: boolean;
  medical?: any;
  name: string;
  noKaddr: string;
  noKrelationship: string;
  nokName: string;
  passportNumber: string;
  physicalAddrs?: any;
  preMedical: boolean;
  premium: number;
  sumAssured: number;
  titleId: string;
  title: any;
  transDate: string;
  wef: Date;
  wet: Date;
  travelPurpose: string;

  //personal accident
  beneficiary: string;
  relationship: string;
  isFullYear: boolean;
  unit: number;
  genderId: string;

  // home
  preOwnership: string;
  tenancy: string;
  description: string;
  address: string;
  postalAddress: string;
  transactionRef: string;
  lgaId: string;
  valueOfContents?: any;
  townId: string;
  intermediaryId: string;
  clientCategoryId: string;
  clientCategory: string;
  homeItems: {
    name: string,
    amount: number
  }[];

  coverDuration: string;


  // shop
  clientCategories: any;
  declearation: any;
  domiciledCountry: any;
  flutterWavePubKey: string;
  flutterWaveSecKey: string;
  gender: any;
  hash: any;
  interswitchMacKey: string;
  interswitchPaymentUrl: string;
  isWemaBank: boolean;
  keepsStockBook: boolean;
  keepsStockBookInSafePlace: boolean;

  lgas: any;
  natureOfBusiness: string;
  natureOfStock: string;
  natureOfStructure: string;
  otherNatureOfStock: any;
  otherNatureOfStructure: any;
  otherStockTakingMode: any;
  otherTypeOfShop: any;
  partnerReference: any;
  ravePublicKey: string;
  referralCode: string;
  stockAmount: string;
  stockTakingMode: string;
  titles: any;
  typeOfShop: string;

  //office

  officeItems: any;
  userId: any;
  wemaBankMacKey: string;
  wemaBankPaymentUrl: string;
  yearsOfDrvExperience: any;

  //health
  occupation: string;
  accidentRate: any;

  //upload
  IdentificationUrl: string;
  UtilityBillUrl: string;
  VehicleLicenseUrl: string;
  PassportUrl: string;
  currencySymbol: any;

  BeneficiaryActive: boolean = true;
  BeneficiaryFullName: string;
  beneficiaryPhone: string;

  //agent
  agentShortDescription: string;

  //autoproposal
  //fullName: string;
  otherNames: string;
  Address: string;
  Email: string;
  Tel: string;
  Bvn: string;
  Occupation: string;
  DateofBirth: Date;
  VehicleMake: string;
  VehicleModel: string;
  VehicleColor: string;
  RegistrationNo: string;
  BodyType: string;
  EngineNo: string;
  Capacity: string;
  ChassisNo: string;
  CostPrice: string;
  ProposedEstimateValu: string;
  Rate: string;
  Premium: string;
  Secondhand: string;
  CompanyName: string;
  CommencementDate: string;
  ProposerSignature: string;
  AgentName: string;
  AgentAddress: string;
  Agency: string;
  AgentCode: string;
  AgentEmail:string;
  UseOfVehicle:string;

  //autoclaim

        signature:string;       
        DriverLastName :string;
        DriverFirstName :string;
        DriverOtherNames :string;
        DriverAddress :string;
        DriverEmail :string;
        DriverTel :string;
        DriverLicenseNumber :string;
        DriverLicenseExpiry:string;
        DateOfLoss :string;
        TimeOfLoss :string;
        Landmark :string;
        LossType :string;
        LossDescription :string;
        LiableParty :string;
        InsuredThirdParty :string;
        PoliceReport :string;
        OccupantInVehicle :string;
        ThirdPartyName :string;
        ThirdPartyRegNo :string;
        ThirdPartyTel :string;
        ThirdPartyAddress :string;
        PolicyNo: string;
        Witnesses :any;

        //criticare
        dob:Date;
        premiumFrequency:string;
        phoneNumber:string;
        policyTerm: string;
        premiumPayment: string;
        targetType: string;
        targetSumAssured:any;
        targetPremium:any;
        actualSumAssured:any;
        actualPremium:any;
        optionalRider:boolean;  
        identificationName: string;
       identificationUrl:string;
       nextOfKinName: string;   
nextOfKinPhone: string;      
nextOfKinRelationship: string;
nextOfKinAddress: string; 

  //
  interswitchProductItemId:string;
  interswitchProductId:string;

  //kyc update
  RevisedSumInsured:any;
  NatureOfBusiness:string;
  IdNumber:string;
  acct_no:string;
  acct_name:string;
  acct_type:string;
  bank_name:string;
  bank_code:string;
  bvn:string;
  bank_branch:string;
  dot: Date;
  pot:string;
}

