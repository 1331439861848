import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


import { SharedModule } from '@app/shared/shared.module';
import { MotorClaimComponent } from './motor-claim.component';
import { MotorClaimRoutingModule } from './motor-claim-routing.module';


@NgModule({
  declarations: [MotorClaimComponent],
  imports: [
    SharedModule,
    CommonModule,
    MotorClaimRoutingModule,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class MotorClaimModule{
}
