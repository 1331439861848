import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HospitalCashRoutingModule } from './hospital-cash-routing.module';
import { HospitalCashComponent } from './hospital-cash.component';
import { SharedModule } from '@app/shared/shared.module';
import { RavepaymentModule } from '@app/components/ravepay/ravepay.module';
import { Angular4PaystackModule } from 'angular4-paystack';


@NgModule({
  declarations: [HospitalCashComponent],
  imports: [
    SharedModule,
    CommonModule,
    HospitalCashRoutingModule,
    RavepaymentModule,Angular4PaystackModule.forRoot('')],
  schemas: [NO_ERRORS_SCHEMA]
})
export class HospitalCashModule{
}
