import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BARoutingModule } from './ba-routing.module';
import { BAComponent } from './ba.component';


@NgModule({
  declarations: [
    BAComponent
  ],
  imports: [
    CommonModule,
    BARoutingModule
  ]
})
export class BAModule { }
