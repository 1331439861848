import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MotorProductService {

  constructor(private baseService: BaseService, private _http: HttpClient) {
    this.baseService.http = _http;
  }

  GetPostMotorSchedule(): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductSchedule/PostMotorSchedule`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetVehicleDetails(plateNo: string): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductService/GetVehicleDetails?numberPlate=${plateNo}`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostMotorSchedule(insurance): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductService/PostMotorSchedule`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }


  PostMotorRenewalSchedule(insurance): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductService/PostMotorRenewalSchedule`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetMotorRenewalSchedule(policyNo:string): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductService/GetMotorRenewalSchedule?policyNo=${policyNo}`
    return this.baseService.http
    .get<any>(url, { headers: this.baseService.headers })
    .pipe(
      catchError(this.baseService._serverError)
    );
  }

  PostAutoProposal(insurance): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductService/PostAutoProposal`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostAutoClaim(insurance): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductService/PostAutoClaim`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }


}
