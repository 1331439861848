import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AIICOeBuisnessInterceptor } from "./aiico-ebusiness-interceptor";
import * as ApiServiceProxies from "./service-proxies";

@NgModule({
  // imports: [
  //   ///other imports
  //   HttpClientModule,
  // ],
  providers: [
    ApiServiceProxies.ClientServiceServiceProxy,
    ApiServiceProxies.CrmServiceServiceProxy,
    // ApiServiceProxies.HospitalCashProductServiceServiceProxy,
    ApiServiceProxies.GadgetProductServiceServiceProxy,
    ApiServiceProxies.ShopProductServiceServiceProxy,
    ApiServiceProxies.OfficeProductServiceServiceProxy,
    ApiServiceProxies.HouseholdProductServiceServiceProxy,
    ApiServiceProxies.LifeRenewalServiceServiceProxy,
    ApiServiceProxies.PersonalAccidentProductServiceServiceProxy,
    ApiServiceProxies.TravelProductServiceServiceProxy,
    ApiServiceProxies.MotorProductServiceServiceProxy,
    ApiServiceProxies.EduShieldProductServiceServiceProxy,
    ApiServiceProxies.HealthProductServiceServiceProxy,
    ApiServiceProxies.PremiumCalculatorServiceProxy,
    ApiServiceProxies.ProductServiceServiceProxy,
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    { provide: HTTP_INTERCEPTORS, useClass: AIICOeBuisnessInterceptor, multi: true },
  ],
})
export class ServiceProxyModule {}



