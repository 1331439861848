import { AfterViewInit, Component, EventEmitter, Inject, NgZone, OnInit, Output, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { RenewalService } from 'src/app/services/renewal.service';





@Component({
  selector: 'app-lifelink',
  templateUrl: './lifelink.component.html',
  styleUrls: ['./lifelink.component.scss']
})
export class LifelinkComponent implements OnInit{
  @Output('action') actionEmitter: EventEmitter<any> = new EventEmitter<any>();
  loading!: boolean | undefined;
  errorStatus$ = new BehaviorSubject('');
  errorMessage$ = new BehaviorSubject('');
  error!: boolean | undefined;
  extraInfo$: BehaviorSubject<any>;
  transactionRef: string;
  outputMessage$ = new BehaviorSubject('');
  outputMessageDescription$ = new BehaviorSubject('');
  polNumber:string;
 

  constructor(
    private route: ActivatedRoute,
    private renewalService:RenewalService,
    public router: Router,
  ) {  }

  ngOnInit() {
    this.polNumber = this.route.snapshot.queryParamMap.get('p');
    if(this.polNumber !==''||this.polNumber.length > 0) {
      this.validatePolicyNumber(this.polNumber);

    }else{
      this.loading=false;
      this.error=true;
      this.outputMessage$.next("Failed");
      this.outputMessageDescription$.next('Invalid Policy Number');
    }

   
  }



  validatePolicyNumber(policyNumber){
    this.loading=true;
    this.renewalService.GetLifePolicyRenewalDetails(policyNumber).subscribe(res => {
      if (res) {
        this.renewalService.renewalDetails = res.result;
        if(this.renewalService.renewalDetails.isSuccessful){
          this.renewalService.renewalDetails["isLifeRenewal"] = true;
          this.renewalService.renewalDetails["isLoanRepayment"] = false;

          this.error=false;
          this.loading=false;

          this.router.navigate(['/policy-renewal']);


        } else {
          this.outputMessage$.next('Policy Not found');
          this.outputMessageDescription$.next('Kindly check your policy number, Thanks');
          this.error=true;
          this.loading=false;
        
        }

      }
    }, err => {
      this.loading=false;
      this.error=true;
      this.outputMessage$.next('Failed');
      this.outputMessageDescription$.next(err.error.error.message);
    }); 
  }


}
