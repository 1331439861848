import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CriticareService {

  constructor(public baseService: BaseService, private _http: HttpClient) {
    this.baseService.http = _http;
  }

  postCriticareSchedule(insurance): Observable<any> {

    const url = `${this.baseService.baseUrl}/CriticareService/PostCriticareSchedule`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  calculateCriticarePremium(insurance): Observable<any> {

    const url = `${this.baseService.baseUrl}/CriticareService/ComputePremium`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }


  //FINALIZED METHOD
  
}
