import { Directive, ElementRef, Renderer2, ViewChild, HostListener } from "@angular/core";

@Directive({ selector: "[appGetPosition]" })
export class GetPositionDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    // this.ChangeBgColor('red');
  }

  @HostListener("window:resize") onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    // we will write this logic later
    // alert("it worked!!!");
    // this.renderer.setStyle(this.el.nativeElement, "right", x + "px");
  }
}
