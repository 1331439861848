import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LifelinkRoutingModule } from './lifelink-routing.module';
import { LifelinkComponent } from './lifelink.component';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [LifelinkComponent],
  imports: [
    SharedModule,
    CommonModule,
    LifelinkRoutingModule
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class LifelinkModule{
}
