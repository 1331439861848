export class TransactionModel {
  public policies: Array<any>;
  public productType: string;
  public totalAmount: number;
  public txnref: string;
  public paymentReference: string;
  public gatewayResponse: string;
  public cardNumber: number;
  public wef: string;
  public wet: string;
  public printPolicyUrl: string;
  public responseCode: string;
  public responseDescription: string;
  public transactionDate: string;
  public printReceiptUrl: string;
  public amount: any;

  constructor() {
    this.policies = [];
    this.productType = '';
    this.totalAmount = 0;
    this.txnref = '';
    this.paymentReference = '';
    this.gatewayResponse = '';
    this.cardNumber = 0;
    this.wef = '';
    this.wet = '';
    this.printPolicyUrl = '';
  }
}
