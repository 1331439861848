import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MotorRenewalRoutingModule } from './motor-renewal-routing.module';
import { MotorRenewalComponent } from './motor-renewal.component';
import { SharedModule } from '@app/shared/shared.module';
import { RavepaymentModule } from '@app/components/ravepay/ravepay.module';
import { Angular4PaystackModule } from 'angular4-paystack';


@NgModule({
  declarations: [MotorRenewalComponent],
  imports: [
    SharedModule,
    CommonModule,
    MotorRenewalRoutingModule,
    RavepaymentModule,Angular4PaystackModule.forRoot('')],
  schemas: [NO_ERRORS_SCHEMA]
})
export class MotorRenewalModule{
}
