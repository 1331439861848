import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { BaseService } from './base.service';
import { Product } from '../models/product';
import { KycFormUpdate } from "@app/models/kycFormUpdate";

@Injectable()
export class ProductsService {
  public allProducts$: BehaviorSubject<Array<Product>>;
  public productsByCategories$: BehaviorSubject<any>;
  public loading$: BehaviorSubject<boolean>;


  categories = [{
    Id: '2d3bb032-98cf-e711-83b7-44850015c0e7',
    Name: 'Auto-Insurance Plans',
    Products: [],
    Description: 'When your responsibility is creating and protecting wealth for a diverse clientele like ours is, you can'
  },
  {
    Id: '2c3bb032-98cf-e711-83b7-44850015c0e7',
    Name: 'Health Insurance',
    Products: [],
    Description: 'When your responsibility is creating and protecting wealth for a diverse clientele like ours is, you can'
  },
  {
    Id: '303bb032-98cf-e711-83b7-44850015c0e7',
    Name: 'Travel Insurance',
    Products: [],
    Description: 'When your responsibility is creating and protecting wealth for a diverse clientele like ours is, you can'
  },
  {
    Id: '313bb032-98cf-e711-83b7-44850015c0e7',
    Name: 'Home Insurance',
    Products: [],
    Description: 'When your responsibility is creating and protecting wealth for a diverse clientele like ours is, you can'
  },
  ]
  selectedProduct: any;

  constructor(private http: HttpClient, public baseService: BaseService) {
    this.baseService.http = http;
    this.allProducts$ = new BehaviorSubject([]);
    this.productsByCategories$ = new BehaviorSubject({});
    this.loading$ = new BehaviorSubject(false);
  }


  getProducts(): Observable<any> {
    const url = `${this.baseService.baseUrl}/ProductService/GetProducts`
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );;
  }

  getProductategories(): Observable<any> {
    const url = `${this.baseService.baseUrl}/ProductCategories/GetAll`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  getProductSubClass(id): Observable<any> {
    const url = `${this.baseService.baseUrl}/ProductService/GetProductSubClassCoverTypes?productId=${id}`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  getSingleProductSubClass(id): Observable<any> {
    const url = `${this.baseService.baseUrl}/ProductService/GetProductSingleSubClassCoverType?Id=${id}`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetTravelSubClassCoverTypes(): Observable<any> {

    const url = `${this.baseService.baseUrl}/ProductService/GetTravelSubClassCoverTypes`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  ComputeTravelPremium(insurance): Observable<any> {
    const url = `${this.baseService.baseUrl}/TravelProductService/ComputeTravelPremium`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostTravelSchedule(insurance): Observable<any> {
    const url = `${this.baseService.baseUrl}/TravelProductService/PostNewTravelSchedule`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostHomeSchedule(insurance): Observable<any> {
    const url = `${this.baseService.baseUrl}/HouseholdProductService/PostHomeSchedule`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostOfficeSchedule(insurance): Observable<any> {
    const url = `${this.baseService.baseUrl}/OfficeProductService/PostOfficeSchedule`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostShopSchedule(insurance): Observable<any> {
    const url = `${this.baseService.baseUrl}/ShopProductService/PostShopSchedule`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostCustomerKyc(kycFormUpdate : KycFormUpdate): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductService/PostCustomerKyc`

    return this.baseService.http
      .post<any>(url, kycFormUpdate, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetTravelPolicyDetails(certNumber): Observable<any> {
    const url = `${this.baseService.baseUrl}/TravelProductService/TravelPolicyDetails`

    return this.baseService.http
      .post<any>(url, certNumber, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetValidateGeneralInsurance(policyNumber): Observable<any> {
    const url = `${this.baseService.baseUrl}/PolicyService/GinPolicyEnquiry?policyNo=${policyNumber}`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetValidateGroupLifeInsurance(policyNumber) : Observable<any> {
    const url = `${this.baseService.baseUrl}/LifeRenewalService/GetGroupLifePolicy?policyNo=${policyNumber}`

    return this.baseService.http
      .get<any>(url, { headers : this.baseService._headers})
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  FinalizeFlutterwavePolicyPayment(ref): Observable<any> {
    const url = `${this.baseService.baseUrl}/RavePaymentService/FinalizeFlutterwavePaymentForLifeRenewal?transactionRef=${ref}&isRetry=true`;

    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  FinalizePaystackPolicyPayment(ref): Observable<any> {
    const url = `${this.baseService.baseUrl}/PaystackPaymentService/FinalizePaystackPaymentForLifeRenewal?transactionRef=${ref}&isRetry=true`;

    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  FinalizeFlutterwavePayment(ref): Observable<any> {
    const url = `${this.baseService.baseUrl}/RavePaymentService/FinalizeFlutterwavePayment?transactionRef=${ref}&isRetry=true`;

    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  FinalizePaystackPayment(ref): Observable<any> {
    const url = `${this.baseService.baseUrl}/PaystackPaymentService/FinalizePaystackPayment?transactionRef=${ref}&isRetry=true`;

    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  FinalizeInterswitchPolicyPayment(ref): Observable<any> {
    const url = `${this.baseService.baseUrl}/InterswitchPaymentService/FinalizeInterswitchPaymentForLifeRenewal?isRetry=true`;

    return this.baseService.http
      .post<any>(url, ref, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  FinalizeInterswitchPayment(ref): Observable<any> {
    const url = `${this.baseService.baseUrl}/InterswitchPaymentService/FinalizeInterswitchPayment?isRetry=true`;

    return this.baseService.http
      .post<any>(url, ref, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

}
