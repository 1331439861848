import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MotorProposalComponent } from './motor-proposal.component';


const routes: Routes = [{ path: "", component: MotorProposalComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MotorProposalRoutingModule { }
