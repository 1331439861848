import { Component, EventEmitter, Inject, NgZone, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ProductsService } from 'src/app/services/products.service';
import { UtilService } from 'src/app/services/util.service';
import { UtilityService } from 'src/app/services/utility.service';
import { RenewalService } from 'src/app/services/renewal.service';
import { InsuranceVm } from 'src/app/models/insurance';
import { MotorProductService } from 'src/app/services/motor-product.service';
import {  MotorScheduleRequest,ProductSubClassCoverTypesModelFinal } from '@shared/service-proxies/service-proxies';
import { InterswitchOptionsModel } from "@app/models/interswitchOptions";
import { RaveOptionsModel } from "@app/models/raveOptions";
import { sha512 } from "js-sha512";
import { DOCUMENT } from "@angular/common";
import differenceInHours from "date-fns/differenceInHours";
import { HospitalCashService } from "@app/services/hospital-cash-service";
import { TransactionModel } from "@app/models/transaction";
import { PaymentService } from "@app/services/payment.service";
import { differenceInCalendarDays } from "date-fns/esm";
import { DirectorData, KycFormUpdate } from "@app/models/kycFormUpdate";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-kyc-form',
  templateUrl: './kyc-form.component.html',
  styleUrls: ['./kyc-form.component.scss']
})
export class KycFormComponent implements OnInit {

  policyNumber:string;
  notification:string;
  
  confirmDetails!: boolean | undefined;
  productDetailsDisabled: BehaviorSubject<boolean>;
  dateOfBirth!: Date | undefined;
  sumAssured?: number | undefined;

  today = new Date();
  current$: BehaviorSubject<number>;
  buttonText$ : BehaviorSubject<string>;
  date = null; // new Date();
  selectedValue$: BehaviorSubject<string>;
  isVisible = false;

  selectedOption: any;
  options$: BehaviorSubject<Array<object>>;

  formatterNaira = (value: number) => `NGN ${value}`;
  parserNaira = (value: string) => value.replace("NGN ", "");

  planCategory$: BehaviorSubject<string>;
  subProductId$: BehaviorSubject<string>;
  selectedPlan$: BehaviorSubject<any>;
  loading!: boolean | undefined;
  errorStatus$ = new BehaviorSubject('');
  errorMessage$ = new BehaviorSubject('');
  showTryAgain$ = new BehaviorSubject(false);
  error!: boolean | undefined;
  extraInfo$: BehaviorSubject<any>;
  titles = [];
  genders = [];
  banks = [];
  insurance: InsuranceVm;
  kycFormUpdate : KycFormUpdate;
  kycSubmitSuccess : boolean;
  directorForm : FormGroup;
  dirItems : FormArray;
  fileData : File = null;
  attachedFilesNames = {
    corporateCertificate : ''
  }

  transactionData$ = new BehaviorSubject(new TransactionModel());

  transactionStatus$: BehaviorSubject<string>;
  transactionDescription$: BehaviorSubject<string>;
  interswitchOptions: InterswitchOptionsModel;

  formErrors$ = new BehaviorSubject([]);
  cat: any;
  countries: any;
  clientCats: any;
  travelPlanObj: { subClassCoverTypes: { productName: string; subClassName: string; }; };
  errorArray: any[];
  caption: string;
  yearsOfDriving: any;

  beneficiaryValid: boolean;
  resendKyc:boolean;
  signature:boolean;
  currentPosition: number;

  showFlutterwavePayment: boolean = false;

  paymentOption = '';
  raveOptions: RaveOptionsModel;
  transactionRef: string;

  isAgentCodeValid: boolean = false;
  agentCodeValidationResponse='';

  constructor(
    public renewalService: RenewalService,
    public utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone, @Inject(DOCUMENT)
    private document: Document,
    private renderer: Renderer2,
    private _formBuilder : FormBuilder,
    public productService: ProductsService,
    public utilityService: UtilityService,
    public motorService: MotorProductService,
    public paymentService: PaymentService,
    ) {

    this.current$ = new BehaviorSubject(0);
    this.extraInfo$ = new BehaviorSubject(new Object());
    this.buttonText$ = new BehaviorSubject("");
    this.insurance = new InsuranceVm();
    this.kycFormUpdate = new KycFormUpdate();
    this.notification='';
    this.resendKyc = false;
    this.kycSubmitSuccess = false;
    
    this.directorForm = _formBuilder.group({
      items: _formBuilder.array([this.createItem()])
    });
  }


setDefaultDate() {
    //  if(!this.insurance.dateOfBirth) {
    this.kycFormUpdate.dateOfBirth = new Date();
    this.kycFormUpdate.dateOfBirth.setFullYear(
      this.kycFormUpdate.dateOfBirth.getFullYear() - 18
    );
    //  }
  }

  ngOnInit() {
    this.getAllBanks();
    this.setDefaultDate();
    if(localStorage.getItem("kycFormUpdate")){
      this.kycFormUpdate = JSON.parse(localStorage.getItem("kycFormUpdate"));
    }
    this.getButtonText(this.current$.value);
    //this.kycFormUpdate.formType = "corporate";
    this.kycFormUpdate.formType = "individual";
  }

  getButtonText(currentValue) {
    switch (currentValue) {
      case 3:
        return this.buttonText$.next("SUBMIT");

      default:
        return this.buttonText$.next("NEXT");
    }
  }

  createItem(): FormGroup {
    return this._formBuilder.group(new DirectorData());
  }

  addNewItem(){
    this.dirItems = this.directorForm.get('items') as FormArray;

    this.kycFormUpdate.directors = this.dirItems.value;

    localStorage.setItem("kycFormUpdate", JSON.stringify(this.kycFormUpdate));
  }

  addNewField(){
    this.dirItems = this.directorForm.get('items') as FormArray;
    this.dirItems.push(this.createItem())
  }

  removeNewField() {
    this.dirItems = this.directorForm.get('items') as FormArray;

    this.dirItems.removeAt(this.dirItems.length - 1);
    this.kycFormUpdate.directors = this.dirItems.value;

    localStorage.setItem("kycFormUpdate", JSON.stringify(this.kycFormUpdate));
  }

  get formData() {
    return <FormArray>this.directorForm.get("items");
  }

  goHome() {
    this.kycSubmitSuccess = false;
    location.href = "/";
  }

  restartKycForm(){
    this.current$.next(0);
    this.kycSubmitSuccess = false;
    this.notification = "";
    this.setDefaultDate();
    if(localStorage.getItem("kycFormUpdate")){
      this.kycFormUpdate = JSON.parse(localStorage.getItem("kycFormUpdate"));
    }
    this.getButtonText(this.current$.value);
  }

  setBankInfo(event){
    const bank = this.banks.find(b => b.code === event);
    this.kycFormUpdate.bankCode = bank.code;
    this.kycFormUpdate.bankName = bank.bankName;
  }

  handleChange(file : any, selectedValue : string) : void {
    this.fileData = <File>file.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onloadend = e => {
      switch(selectedValue) {
        case "certOfInc":
          this.kycFormUpdate.corprateCertificate = reader.result as string;
          this.attachedFilesNames.corporateCertificate = this.fileData.name;
          break;
        default:
          this.kycFormUpdate.corprateCertificate = reader.result as string;
          this.attachedFilesNames.corporateCertificate = this.fileData.name;
          break;
      }

      this.validateUploads;
    }
  }

  disabledDOB = (current: Date): boolean => {
    var date = new Date();
    var eighteenYearsAgo = date.getFullYear() - 18;
    date.setFullYear(eighteenYearsAgo)
    return differenceInCalendarDays(current, date) > 0;
  };

  disabledIncDate = (current : Date) : boolean => {
    var today = new Date();

    return differenceInCalendarDays(current, today) > 0;
  }

  disabledExpiryDate = (current : Date) : boolean => {
    var today = new Date();

    return differenceInCalendarDays(current, today) < 0;
  }

  validateUploads() : boolean{
    if(this.kycFormUpdate.formType.toLowerCase() === 'corporate'){
      return !!(this.kycFormUpdate.corprateCertificate);
    }
    return true;
  }

  validateKycForm(){
    this.insurance.RevisedSumInsured = this.formatAmountToNumber(this.insurance.RevisedSumInsured)
    this.formErrors$.next([]);
    const errorArray = [];

    if (!this.insurance.lastName) {
      errorArray.push('Please fill in your surname');
    } else if (!this.insurance.firstName) {
      errorArray.push('Please fill in your first name');

    } else if(!this.insurance.otherNames) {
      errorArray.push('Please fill in your other names');
    } else if(!this.insurance.IdNumber) {
      errorArray.push("Please fill in your Id Number");
    } else if(!this.insurance.Occupation){
      errorArray.push("Please fill in your occupation");
    } else if(!this.insurance.NatureOfBusiness) {
      errorArray.push("Please fill in your nature of business");
    } else if(!this.insurance.Address) {
      errorArray.push("Please fill in your address")
    } else if(!this.insurance.Tel){
      errorArray.push("Please fill in your phone number")
    } else if(this.insurance.Tel.length !== 11 || isNaN(+this.insurance.Tel) || !Number.isInteger(+this.insurance.Tel)){
      errorArray.push('Please fill in a valid phone number');
    } else if(!this.insurance.Email){
      errorArray.push("Please fill in your email")
    } else if(!this.utilityService.validateEmail(this.insurance.Email)){
      errorArray.push("Email is invalid");
    } else if(!this.insurance.DateofBirth){
      errorArray.push("Please in your date of birth");
    } else if(!this.insurance.RevisedSumInsured){
      errorArray.push("Please fill in your revised sum insured")
    } else if(!this.insurance.acct_name){
      errorArray.push("Please fill in your account name")
    } else if(!this.insurance.acct_no){
      errorArray.push("Please fill in your account number")
    } else if(this.insurance.acct_no.length !== 10 || isNaN(+this.insurance.acct_no) || !Number.isInteger(+this.insurance.acct_no)) {
      errorArray.push('Please fill in a valid bank account number');
    } else if(!this.insurance.acct_type){
      errorArray.push("Please fill in your account type")
    } else if(this.insurance.acct_type.toLowerCase() !== "current" && this.insurance.acct_type.toLowerCase() !== "savings" && this.insurance.acct_type.toLowerCase() !== "dorm"){
      errorArray.push("Account type can either be current, savings or dorm");
    } else if(!this.insurance.bvn) {
      errorArray.push("Please fill in your bvn");
    }else if(this.insurance.bvn.length !== 10 || isNaN(+this.insurance.bvn) || !Number.isInteger(+this.insurance.bvn)){
      errorArray.push("Please fill in a valid bvn");
    }else if(!this.insurance.bank_code || !this.insurance.bank_name){
      errorArray.push("Please choose your bank name");
    } else if(!this.insurance.bank_branch){
      errorArray.push("Please fill in your bank branch");
    }else if(!this.insurance.pot){
      errorArray.push("Please fill in your purpose of transaction");
    }else if (!this.insurance.dot){
      errorArray.push("Please in the date of transaction");
    }

    this.formErrors$.next(errorArray);
    return !errorArray.length;
  }

  validateStepZero(){
    this.formErrors$.next([]);
    const errorArray = [];

    if(!this.kycFormUpdate.formType){
      errorArray.push("Please choose a form type")
    }

    if(this.kycFormUpdate.formType.toLowerCase() === "individual"){
      this.kycFormUpdate.indvidualKycType = true;
      this.kycFormUpdate.corprateKycType = false;
    }else{
      
      this.kycFormUpdate.corprateKycType = true;
      this.kycFormUpdate.indvidualKycType = false;
    }

    this.formErrors$.next(errorArray);
    return !errorArray.length;
  }

  validateStepOne(){
    this.formErrors$.next([]);
    const errorArray = [];

    if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.firstName){
      errorArray.push("Please fill in your first name");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.lastName){
      errorArray.push("Please fill in your surname");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.gender){
      errorArray.push("Please choose a gender")
    }else if(!this.kycFormUpdate.email){
      errorArray.push("Please fill in your individual email")
    }else if(!this.utilityService.validateEmail(this.kycFormUpdate.email)){
      errorArray.push("Individual email is invalid");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.tel){
      errorArray.push("Please fill in your phone number")
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && this.kycFormUpdate.tel.length !== 11 || isNaN(+this.kycFormUpdate.tel) || !Number.isInteger(+this.kycFormUpdate.tel)){
      errorArray.push('Please fill in a valid phone number');
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.occupation){
      errorArray.push("Please fill in your occupation");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.taxId){
      errorArray.push("Please fill in your tax id");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.sourceOfWealth){
      errorArray.push("Please fill in your source of wealth");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.nationality){
      errorArray.push("Please fill in your nationality")
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.address){
      errorArray.push("Please fill in your address name");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.addressType){
      errorArray.push("Please fill in your address-type");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && this.kycFormUpdate.addressType.toLowerCase() === 'business' && !this.kycFormUpdate.employerName){
      errorArray.push("Please fill in your employer name");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corprate' && !this.kycFormUpdate.companyName){
      errorArray.push("Please fill in your company name");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corprate' && !this.kycFormUpdate.incorporatedNo){
      errorArray.push("Please fill in your incorporated number");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corprate' && !this.kycFormUpdate.corprateEmail){
      errorArray.push("Please fill in your corporate email")
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corprate' && !this.utilityService.validateEmail(this.kycFormUpdate.corprateEmail)){
      errorArray.push("corporate email is invalid");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corprate' && !this.kycFormUpdate.corprateTaxNumber){
      errorArray.push("Please fill in your tax number");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corprate' && !this.kycFormUpdate.incorporationDate){
      errorArray.push("Please choose your incorporation date");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corprate' && !this.kycFormUpdate.incorporationState){
      errorArray.push("Please fill in your incorporation state");
    }

    if(this.kycFormUpdate.addressType.toLowerCase() === "business"){
      this.kycFormUpdate.isBusinessAddress = true;
    }else{
      this.kycFormUpdate.isBusinessAddress = false;
    }

    this.formErrors$.next(errorArray);
    return !errorArray.length;
  }

  validateStepTwo(){
    this.formErrors$.next([]);
    const errorArray = [];

    if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.accountName){
      errorArray.push("Please fill in your account name")
    } else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.accountNo){
      errorArray.push("Please fill in your account number")
    } else if(this.kycFormUpdate.formType.toLowerCase() === 'individual'  && (this.kycFormUpdate.accountNo.length !== 10 || isNaN(+this.kycFormUpdate.accountNo) || !Number.isInteger(+this.kycFormUpdate.accountNo))) {
      errorArray.push('Please fill in a valid account number');
    } else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.accountType){
      errorArray.push("Please choose your account type")
    } else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && (!this.kycFormUpdate.bankCode || !this.kycFormUpdate.bankName)){
      errorArray.push("Please choose your bank name");
    } else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.bankBranch){
      errorArray.push("Please fill in your bank branch");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.transactionPurpose){
      errorArray.push("Please fill in your purpose of transaction");
    }else if (this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.transactionDate){
      errorArray.push("Please in the date of transaction");
    }

    this.formErrors$.next(errorArray);
    return !errorArray.length;
  }

  validateStepThree(){
    this.formErrors$.next([]);
    const errorArray = [];

    if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorFirstName){
      errorArray.push("Please fill in your director first-name");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorLastName){
      errorArray.push("Please fill in your director last-name");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorPosition){
      errorArray.push("Please fill in your director position");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorTel){
      errorArray.push("Please fill in your director phone-number");
    } else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && (this.kycFormUpdate.directors[0].directorTel.length !== 11 || isNaN(+this.kycFormUpdate.directors[0].directorTel) || !Number.isInteger(+this.kycFormUpdate.directors[0].directorTel))){
      errorArray.push('Please fill in a valid director phone-number');
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.idMode){
      errorArray.push("Please choose a mode of id");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorIdMode){
      errorArray.push("Please choose a director mode-of-id");
    }else if((this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.idNo)){
      errorArray.push("Please fill in your individual id number");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorIdNumber){
      errorArray.push("Please fill in your director id-number")
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.idIssuedDate){
      errorArray.push("Please choose your individual id issued date");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorIdIssuedDate){
      errorArray.push("Please fill in your director id-issued-date")
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.idExpiryDate){
      errorArray.push("Please choose your individual id expiry date");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorIdExpiryDate){
      errorArray.push("Please fill in your director id-expiry-date")
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.issuingCountry){
      errorArray.push("Please fill in your individual issuing country");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'individual' && !this.kycFormUpdate.dateOfBirth){
      errorArray.push("Please choose your date of birth");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorOccupation){
      errorArray.push("Please fill in your director-occupation");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorEmail){
      errorArray.push("Please fill in your director-email");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.utilityService.validateEmail(this.kycFormUpdate.directors[0].directorEmail)){
      errorArray.push("Director-email is invalid")
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorTitle){
      errorArray.push("Please fill in your director-title");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorGender){
      errorArray.push("Please fill choose your director-gender");
    }else if(this.kycFormUpdate.formType.toLowerCase() === 'corporate' && !this.kycFormUpdate.directors[0].directorAddress){
      errorArray.push("Please fill in your director-address");
    }

    this.formErrors$.next(errorArray);
    return !errorArray.length;
  }


  saveKycForm() {
    this.loading = true;
    this.error = false;
    localStorage.setItem("kycFormUpdate", JSON.stringify(this.kycFormUpdate));

    const currVal = this.current$.value;
    if(this.checkSteps(currVal)){
      this.postCustomerKyc();
    }else{
      this.loading = false;
      this.error = false;
    }
  }

  verifyBankDetails() {
    this.loading = true;
    this.error = false;

    const bankDetails =  {
      firstname: this.kycFormUpdate.firstName, 
      lastname: this.kycFormUpdate.lastName,
      accountNumber: this.kycFormUpdate.accountNo,
      bankCode: this.kycFormUpdate.bankCode, 
      isCompliance: true,
      policyNo : "",
      isFaceMatch : false,
    }

    this.utilService.verifyBankDetails(bankDetails).subscribe(data => {
      if(data && data.result && data.result.success) {
        this.error = false;
        this.current$.next(this.current$.value + 1);
        this.getButtonText(this.current$.value);
      }else{
        this.error = false;
        this.current$.next(this.current$.value + 1);
        this.getButtonText(this.current$.value);
      }
      this.loading = false;
    }), error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;  
    }
  }

  postCustomerKyc(){
      //post to endpoint
      this.productService.PostCustomerKyc(this.kycFormUpdate).subscribe(res => {
        if (res) {
          if(res.result === true){
            this.notification='Customer KYC successfully submitted.';
            this.kycFormUpdate = new KycFormUpdate();
            this.kycSubmitSuccess = true;
            this.formErrors$.next([]);
  
          }else{this.notification='Customer KYC submission failed, try again later';}
          this.error = false;
          this.loading = false;
        }
      }, error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error=true;
      }, () => this.loading=false)
  }

  getAllBanks(){
    this.loading = true;
    this.error = false;

    this.utilService.getAllBanks().subscribe(data => {
      if(data){
        this.banks = [...data.result.table];
        localStorage.setItem("banks", JSON.stringify(this.banks));
      }
      this.loading = false;
    }), error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;
    }
  }

  formatAmountToString(val) {
    try {

      if (val) {
        this.insurance.RevisedSumInsured = this.formatAmountToNumber(val);
        this.insurance.RevisedSumInsured = (parseInt(val.toString().replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US');
      }
    }
    catch (e) { }
  }

  formatAmountToNumber(val) {
    if (val)
      return val = typeof val == 'number' ? val : parseFloat(val.replace(/,/g, ''));
  }

  increaseCurrentNumber() {
    localStorage.setItem("kycFormUpdate", JSON.stringify(this.kycFormUpdate));
    localStorage.setItem("attachedFilesNames", JSON.stringify(this.attachedFilesNames))

    const currVal = this.current$.value;
    if(this.checkSteps(currVal)){
      if(currVal < 2 || (currVal === 2 && this.kycFormUpdate.formType.toLowerCase() === "corporate")){
        this.current$.next(this.current$.value + 1);
      }else{
        this.verifyBankDetails();
      }
    }

    //this.current$.next(this.current$.value + 1);
    this.getButtonText(this.current$.value);
  }

  decreaseCurrentNumber() {
    if(this.current$.value > 0){
      this.formErrors$.next([]);
      this.current$.next(this.current$.value - 1);
      this.getButtonText(this.current$.value);
    }
  }

  checkSteps(currentVal : number) : boolean {
    if(currentVal === 0){
      return this.validateStepZero();
    }else if(currentVal === 1){
      return this.validateStepOne();
    }else if(currentVal === 2){
      return this.validateStepTwo();
    }else if(currentVal === 3){
      return this.validateStepThree();
    }
    return true;
  }

/* 
  confirmFormFields() 
  {

      this.loading=true;
      this.error=false;
      this.motorService.PostMotorRenewalSchedule(this.productScheduleRequest).subscribe(res => {
        if (res) {

          this.error = false;
          this.loading = false;

          this.transactionRef = res.result.transactionRef;
          this.current$.next(this.current$.value + 1);
        }
      }, error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error=true;
      }, () => this.loading=false)
    } */

  isInputValid(name: string) {
    if (this.formErrors$.value.length) {
      return this.formErrors$.value[0].toLowerCase().includes(name);
    }
    return false;
  };

  joinArr(arr) {
    if (arr) {
      return arr.join(', ');
    }

    return 'N/A';
  }

  formatCurrency(value) {
    const num = parseFloat(value);

    if (num === num) {
      return `\u20A6${value}`;
    }

    return value;
  }

}
