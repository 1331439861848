import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA, Injector } from "@angular/core";
// import { AbpModule } from '@abp/abp.module';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgZorroAntdModule, NZ_I18N, en_US } from "ng-zorro-antd";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PlatformLocation, registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { NzSelectModule } from "ng-zorro-antd/select";
import { SharedModule } from "./shared/shared.module";
import { NzSpinModule } from 'ng-zorro-antd/spin';


// services
import { WindowRef } from "./shared/window.service";
import { GetPositionDirective } from "./pages/home/home.directive";


// components
import { NavComponent } from "./components/nav/nav.component";
import { FooterComponent } from "./components/footer/footer.component";
import { PlanCardsComponent } from "./components/plan-cards/plan-cards.component";


// pages
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";
import { ServiceLocator } from './models/general/service-locator';
import { ProductsService } from "./services/products.service";
import { UtilityService } from './services/utility.service';
import { PaymentService } from './services/payment.service';
// import { BaseService } from './services/base.service';
import { HospitalCashModule } from './pages/hospital-cash/hospital-cash.module';
import { API_BASE_URL, HospitalCashProductServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { BaseService } from "./services/base.service";
import {TermlifeModule} from './pages/term-life/termlife.module';
import {LifelinkModule} from './pages/lifelink/lifelink.module';
import {LoanlinkModule} from './pages/loanlink/loanlink.module';
import {TermlifeBeneficiaryModule} from './pages/termlife-beneficiary/termlife-beneficiary.module';
import {MotorRenewalModule} from './pages/motor-renewal/motor-renewal.module';
import { BAComponent } from './pages/bank-assurance/ba.component';
import { AgentReferralModule } from './pages/agent-referral/agent-referral.module';
import { BAModule } from "./pages/bank-assurance/ba.module";
import { WomenHealthPlanModule } from "./pages/women-healthplan/women-healthplan.module";
import { MotorProposalModule } from "./pages/motor-proposal/motor-proposal.module";
import { NonlifeRenewalModule } from "./pages/nonlife-renewal/nonlife-renewal.module";
import { MotorClaimModule } from "./pages/motor-claim/motor-claim.module";
import { CriticareModule } from "./pages/criticare/criticare.module";
import { KycFormModule } from "./pages/kyc-form/kyc-form.module";
import { ModalComponent } from './modal/modal.component';
registerLocaleData(en);

export function getRemoteServiceBaseUrl(): string {
  return "https://portal.aiicoplc.com"; //this.baseService.getApiBaseUrl(); //'http://localhost:53262/api/services/app';
}
@NgModule({
  declarations: [
    AppComponent,
    GetPositionDirective,
    FooterComponent,
    PlanCardsComponent,
    NavComponent,
    NotFoundComponent,
    MainLayoutComponent,
    ModalComponent,
    // BAComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgZorroAntdModule,
    NzSelectModule,
    NzSpinModule,
    SlickCarouselModule,
    // AbpModule,
    ServiceProxyModule,
    SharedModule.forRoot(),
    HospitalCashModule,
    TermlifeModule,
    LifelinkModule,
    LoanlinkModule,
    TermlifeBeneficiaryModule,
    MotorRenewalModule,
    MotorProposalModule,
    MotorClaimModule,
    BAModule,
    AgentReferralModule,
    WomenHealthPlanModule,
    NonlifeRenewalModule,
    CriticareModule,
    KycFormModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [ProductsService, UtilityService, PaymentService, HospitalCashProductServiceServiceProxy,
    { provide: NZ_I18N, useValue: en_US },
    { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
    WindowRef],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
    // baseService = new BaseService();
  }
}
