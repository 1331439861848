import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { RenewalService } from 'src/app/services/renewal.service';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-travel-validation-modal',
  templateUrl: './travel-validation-modal.component.html',
  styleUrls: ['./travel-validation-modal.component.scss']
})
export class TravelValidationModalComponent implements OnInit {

  @Output('action') actionEmitter: EventEmitter<any> = new EventEmitter<any>();
  isVisible = false;
  modalTitle$: BehaviorSubject<string>;
  selectedModal$: BehaviorSubject<string>;
  validateForm: FormGroup;
  loading$: BehaviorSubject<boolean>;
  error$: BehaviorSubject<boolean>;
  errorStatus$: BehaviorSubject<string>;
  errorMessage$: BehaviorSubject<string>;
  isCertificateValid: boolean;
  showCertificateModal: boolean;
  assurance: any;
  // policyNumber: string;

  constructor(private fb: FormBuilder, public renewalService: RenewalService, public route: Router, private productService: ProductsService) {
    this.modalTitle$ = new BehaviorSubject("");
    this.selectedModal$ = new BehaviorSubject("");

    this.loading$ = new BehaviorSubject(false);
    this.errorStatus$ = new BehaviorSubject('');
    this.errorMessage$ = new BehaviorSubject('');
    this.error$ = new BehaviorSubject(false);
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      validateNumber: ["", [Validators.required]]
    });
  }

  setModalDetails(options) {
    switch (options) {
      case "life":
        return "Renew your Life Insurance Policy";
      case "loan":
        return "Repay your loan";
      case "travel":
        return "Validate your Travel Certificate";

      case "term":
        return "Validate your Term Assurance Certificate";
      default:
        return "Signup";
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleSuccessCancel() {
    this.showCertificateModal = false;
  }

  showModal() {
    this.selectedModal$.next('');
    this.modalTitle$.next(this.setModalDetails('travel'));
    this.isVisible = true;
  }

  submitForm(): void {
    // tslint:disable-next-line: forin
    this.loading$.next(true);
    this.error$.next(false);

    var value = this.validateForm.controls.validateNumber.value;

    if (this.selectedModal$.value == "client") {
      this.renewalService.GetClientPolicies(value).subscribe(res => {
        if (res && res.result.isSuccessful == true) {
          this.renewalService.profile = res.result.clientPolicyList;
          this.renewalService.profile['emails'] = res.result.emails;

          for (const key in this.validateForm.controls) {
            this.validateForm.controls[key].markAsDirty();
            this.validateForm.controls[key].updateValueAndValidity();
          }

          this.handleCancel();

          this.isVisible = false;
          this.actionEmitter.emit(false);
          this.route.navigate(['/client-profile']);
          this.loading$.next(false);
        }
        else {
          this.isVisible = false;
          this.actionEmitter.emit(false);
          this.errorMessage$.next("Invalid policy number");
          this.error$.next(true);
          this.loading$.next(false);
        }

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error$.next(true);
        this.loading$.next(false);
      }
    }
    else if (this.selectedModal$.value == "agent") {
      this.renewalService.GetAgentDetails(value).subscribe(res => {
        if (res && res.result.isSuccessful == true) {
          this.renewalService.profile = res.result.agentdetailByEmailModels;

          for (const key in this.validateForm.controls) {
            this.validateForm.controls[key].markAsDirty();
            this.validateForm.controls[key].updateValueAndValidity();
          }

          this.handleCancel();

          this.isVisible = false;
          this.actionEmitter.emit(false);
          this.route.navigate(['/agent-profile']);
          this.loading$.next(false);
        }
        else {
          this.errorMessage$.next("Invalid Agent Code");
          this.isVisible = false;
          this.actionEmitter.emit(false);
          this.error$.next(true);
          this.loading$.next(false);
        }

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error$.next(true);
        this.loading$.next(false);
      }
    }
    else if (this.selectedModal$.value == "term") {
      this.renewalService.GetTravelTermAssurance(value).subscribe(res => {
        this.showCertificateModal = true;
        this.handleCancel();

        if (res && res.result) {
          this.isCertificateValid = true;
          this.assurance = res.result;
        }
        else {
          this.isCertificateValid = false;
        }
        this.loading$.next(false);

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error$.next(true);
        this.loading$.next(false);
      }
    }
    else if (this.selectedModal$.value == "life") {
      this.renewalService.GetLifePolicyRenewalDetails(value).subscribe(res => {
        if (res) {
          this.renewalService.renewalDetails = res.result;
          this.renewalService.renewalDetails["isLifeRenewal"] = true;
          this.renewalService.renewalDetails["isLoanRepayment"] = false;

          this.route.navigate(['/policy-renewal']);
        }
        this.loading$.next(false);

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error$.next(true);
        this.loading$.next(false);
      }
    }
    else if (this.selectedModal$.value == "loan") {
      this.renewalService.GetLoanListRepaymentDetails(value).subscribe(res => {
        if (res) {
          this.renewalService.renewalDetails = res.result;
          if(res.result.loanList.length > 0) {
          this.renewalService.GetLoanRepaymentDetailsWithRef(value, res.result.loanList[0].id).subscribe(res_ => {
            if (res_) {
              this.renewalService.renewalDetails = res_.result;
              this.renewalService.renewalDetails["isLoanRepayment"] = true;
              this.renewalService.renewalDetails["isLifeRenewal"] = false;
              this.renewalService.renewalDetails["loanId"] = res.result.loanList[0].id;
              this.route.navigate(['/policy-renewal']);
            }
            this.loading$.next(false);

          }), error => {
            this.errorStatus$.next(`${error.status}`);
            this.errorMessage$.next(error.statusText);
            this.error$.next(true);
            this.loading$.next(false);
          }
        }
        else {
          this.errorMessage$.next('Invalid Policy number');
          this.error$.next(true);
          this.loading$.next(false);
        }
      }

      else {
        this.loading$.next(false);
      }

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error$.next(true);
        this.loading$.next(false);
      }
    }
    else if (this.selectedModal$.value == "travel") {
      var cert = {
        searchBy: 0,
        param: value
      }
      this.productService.GetTravelPolicyDetails(cert).subscribe(res => {
        this.showCertificateModal = true;
        this.handleCancel();

        if (res && res.result.isSuccessful == true) {
          this.isCertificateValid = true;
          this.assurance = res.result.travelPolicyDetailsList[0];
        }
        else {
          this.isCertificateValid = false;
        }
        // this.route.navigate(['/policy-renewal']);
        this.loading$.next(false);

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error$.next(true);
        this.loading$.next(false);
      }
    }
  }

}
