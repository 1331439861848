export class Nonlife {

    poL_POLICY_NO: string;
    PolicyNo: string;
    poL_POLICY_COVER_FROM : string;
    clnT_EMAIL_ADDRS: string;
    tel: string;
    poL_POLICY_COVER_TO: string;
    poL_RENEWAL_DT: string;
    poL_CUR_SYMBOL: string;
    clnT_NAME: string;
    poL_NETT_PREMIUM: string;
    poL_COMM_AMT: string;
    prO_DESC: string;
    premium: number;
    transDate: string;
    wef: string;
    wet: string;
  Email: string;
  }