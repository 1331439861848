export const HOMESLIDERCONFIG = {
  arrows: false,
  className: "center",
  centerMode: true,
  dots: true,
  infinite: true,
  slidesToShow: 3,
  speed: 500,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1
      }
    }
  ]
};

export const YEARS_OF_DRIVING = [
  "0 year",
  "1 year",
  "2 years",
  "3 years",
  "4 years",
  "5 years",
  "6 years",
  "7 years",
  "8 years",
  "10 years",
  "11 years",
  "12 years",
  "13 years",
  "14 years",
  "15 years",
  "16 years",
  "17 years",
  "18 years",
  "19 years",
  "20 years",
  "21 years",
  "22 years",
  "23 years",
  "24 years",
  "25 years",
  "26 years",
  "27 years",
  "28 years",
  "29 years",
  "30 years",
  "31 years",
  "32 years",
  "33 years",
  "34 years",
  "35 years",
  "36 years",
  "37 years",
  "38 years",
  "39 years"
];

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const CLIENTS = [
  { img: "7up.png" },
  { img: "halliburton.png" },
  { img: "esso.png" },
  { img: "halliburton.png" },
  { img: "baker_hughes.png" }
];

export const AUTO_INSURANCE = [
  {
    id: "auto-1",
    name: "auto comprehensive",
    summary:
      "We offer a wide range of products and services which are  tailored for you",
    image: "./assets/images/insurance/auto/comprehensive.jpg"
  },
  {
    id: "auto-2",
    name: "third party",
    // tslint:disable-next-line: max-line-length
    summary:
      "AIICO Third Party protects the insured from damage to other people, vehicles and property(s) in the event of an accident whilst using his/her vehicle",
    image: "./assets/images/insurance/auto/3rd-party.jpg"
  }
];

export const PROPERTY_INSURANCE = [
  {
    id: "property-1",
    name: "home content",
    // tslint:disable-next-line: max-line-length
    summary:
      "AIICO Home Insurance protects you financially in the event that your home and property is damaged as a result of burglary or fire outbreak",
    image: "./assets/images/insurance/property/home.jpeg"
  },
  {
    id: "property-2",
    name: "office content",
    // tslint:disable-next-line: max-line-length
    summary:
      "AIICO Office Content Insurance protects you financially in the event that your office and property is damaged as a result of burglary or fire outbreak.",
    image: "./assets/images/insurance/property/office.jpg"
  },
  {
    id: "property-3",
    name: "shop",
    summary:
      "We offer a wide range of products and services which are  tailored for you",
    image: "./assets/images/insurance/property/shop.jpg"
  }
];

export const PLANICONS = {
  "f1326cce-47e7-e711-a2be-005056a02281": "comprehensive.svg",
  "fef672bd-faf1-e711-a2c0-005056a02281": "3rd-party.svg",
  "cb00e3f3-9feb-e711-a2be-005056a02281": "travel.svg",
  "897a91f5-6225-e811-a2cc-005056a02281": "casual.svg",
  "52f33528-6325-e811-a2cc-005056a02281": "home.svg",
  "1a2f284a-6325-e811-a2cc-005056a02281": "office.svg",
  "939e9f6c-6325-e811-a2cc-005056a02281": "shop.svg",
  "9f304355-43c0-ea11-a336-005056a00da2": "casual.svg"
};

export const EXTRA_INFO = [
  {
    summary: "client-agent profile",
    images: {
      form: "./assets/images/extra/client-profile.jpg"
    }
  },
  {
    id: "f1326cce-47e7-e711-a2be-005056a02281",
    summary:
      "AIICO Private Motor Comprehensive protects the insured from damage to other people, vehicles and property(s) in the event of an accident whilst using his/her vehicle.",
    images: {
      banner: "./assets/images/extra/auto-banner.jpg",
      form: "./assets/images/extra/auto-form.jpg"
    }
  },
  {
    id: "fef672bd-faf1-e711-a2c0-005056a02281",
    summary:
      "AIICO Third Party protects the insured from damage to other people, vehicles and property(s) in the event of an accident whilst using his/her vehicle.",
    premiums: [
      "N15,000.00 for Private Motor",
      "N20,000.00 for buses",
      "N100,000.00 for Trucks"
    ],
    others: {
      "The scope of cover include": [
        "3rd party property damage up to N3 million",
        "3rd party bodily injury – Unlimited"
      ]
    },
    images: {
      banner: "./assets/images/extra/auto-banner.jpg",
      form: "./assets/images/extra/auto-form.jpg"
    }
  },
  {
    id: "cb00e3f3-9feb-e711-a2be-005056a02281",
    summary:
      "AIICO Third Party protects the insured from damage to other people, vehicles and property(s) in the event of an accident whilst using his/her vehicle.",
    premiums: [
      "N5,000.00 for Private Motor",
      "N7,500.00 for Haice buses, pick up, mini trucks",
      "N25,000.00 for Truck"
    ],
    others: {
      "The scope of cover include": [
        "3rd party property damage up to N1 million",
        "3rd party bodily injury – Unlimited"
      ]
    },
    images: {
      banner: "./assets/images/extra/travel-banner.jpg",
      form: "./assets/images/login/picture1.png"
    }
  },
  {
    id: "897a91f5-6225-e811-a2cc-005056a02281",
    summary:
      "AIICO Shield Personal Accident Insurance is designed to provide an accident only insurance whereby the Insured, in any mishap, has access to some level of compensations for death, disability and medical treatment for injuries following accident.",
    premiums: [
      "The policy is structured on flat premium basis of N1,500.00 for single unit. (Multiple premiums secure equivalent multiple benefits)."
    ],
    others: {
      "Scale of Compensation for Disability": [
        "Loss of either one eye: N100,000.00",
        "Loss of both eyes: N250,000.00",
        "Loss of one hand: N100,000.00",
        "Loss of both hands: N250,000.00 etc"
      ]
    },
    images: {
      banner: "./assets/images/extra/personal-banner.jpg",
      form: "./assets/images/extra/personal-form.jpg"
    }
  },
  {
    id: "35b20387-7439-ec11-aed3-9829a66a9d08",
    summary:
      "AIICO’s Criticare is a health insurance plan, designed to provide financial cover against critical illnesses. It helps you to make substantial provision to manage the costs for care and treatments, giving you wholesome peace of mind.",
    premiums: [
      "Premium Payment Options are One-off, Monthly, Quarterly, Semi-annually, Annually."
    ],
    others: {
      benefits: [
        "Criticare provides a cover to individuals against the risk of contracting a critical illness over the term of the policy. Anyone can be diagnosed with a critical illness at any time, and surviving these illnesses are very possible with the advances in medicine today.",
        "It provides additional coverage for the covered illnesses above provisions under health insurance because these emergencies or illnesses often incur greater than average medical costs. It provides access to care at specialist hospitals, taking over from where your health insurance stops.",
        "It provides financial relief on individual’s hard-earned savings in trying times. It ensures that individuals can survive a critical illness without depleting financial resources.",
        "It ensures that individuals maintain healthy lifestyles through the free health and wellness checks during the policy term, and the close call benefits."
      ]
    },
    images: {
      banner: "./assets/images/extra/criticare-banner.png",
      form: "./assets/images/extra/criticare-form.jpg"
    }
  },
  {
    id: "52f33528-6325-e811-a2cc-005056a02281",
    summary:
      "AIICO Home Insurance protects you financially in the event that your home and property is damaged as a result of burglary or fire outbreak. It is designed essentially for those living in rented apartment to cover their contents only against loss as a result of burglary, fire & special peril.",
    premiums: [
      "The policy is structured on flat premium basis of N10, 000.00/annum (Multiple entries is not allowed on this policy)."
    ],
    others: {
      benefits: [
        "Compensation to the tune of N2million for fire damage to the content only",
        "Compensation to the tune of N1million for burglary",
        "Rent for alternative accommodation up to N250,000",
        "Personal accident cover (disability, death, & medical expense) for the insured and spouse for claims occurring in the insured residence."
      ]
    },
    images: {
      banner: "./assets/images/extra/home-banner.jpg",
      form: "./assets/images/extra/home-form.jpg"
    }
  },
  {
    id: "1a2f284a-6325-e811-a2cc-005056a02281",
    summary:
      "AIICO Office Content Insurance protects you financially in the event that your office and property is damaged as a result of burglary or fire outbreak. It is designed essentially to cover the office contents only against loss as a result of burglary, fire & special peril.",
    premiums: [
      "The policy is structured on flat premium basis of N10,000.00/annum(Multiple entries is not allowed on this policy)."
    ],
    others: {
      "benefits:": [
        "Compensation to the tune of N2million for fire damage to the office content only",
        "Compensation to the tune of N1million for burglary",
        "Up to N250,000 for alternative office accomodation"
      ]
    },
    images: {
      banner: "./assets/images/extra/office-banner.jpg",
      form: "./assets/images/extra/office-form.jpg"
    }
  },
  {
    id: "939e9f6c-6325-e811-a2cc-005056a02281",
    summary:
      "AIICO Shop Insurance is designed to reduce the burden of shop owners, and enable them to start again in the event of fire outbreak. It provides compensation up to the tune of N2 million in the event that stocks, materials and/or wares in trade is affected by fire & special peril.",
    premiums: [
      "The policy is structured on flat premium basis of N10,000.00/annum (Not more than maximum of 3 multiple entries is allowed on this policy)."
    ],
    others: {
      benefits: [
        "Fire & special peril: In the case of fire incident affecting the shop, the limit of liability is only for loss of wares/stocks/materials in trade of the shop to a maximum of N2million (single unit).",
        "Public liability: This benefit is as a result of possible effect of loss on neighbours thus giving rise to some legal claims on third party liability.",
        "Permanent disability: In the event of bodily injuries resulting in disability of the insured person.",
        "Loss of both hands: N250,000.00 etc"
      ]
    },
    images: {
      banner: "./assets/images/extra/shop-banner.jpg",
      form: "./assets/images/extra/shop-form.jpg"
    }
  },
  {
    id: "9f304355-43c0-ea11-a336-005056a00da2",
    summary:
      `
    Required Documentation:
•    Completed Proposal Form
•    Proof of identity of the Proposed. One of the following is acceptable: Driver’s License, Voter’s Card , National Identity Card
•    Evidence of premium payment.
    `,
    premiums: [
      `Areas of cover:
      •    This product covers the risk of an individual being hospitalized in any registered NHIS hospital for more than 3 nights
      •    The policyholder must be between the ages of 18 and 64 years and be in a normal state of health when he or she first enrols
      `,
      `Claims Required Documentation:
      •    Original Copy of the Policy Document will be required.
      •    Proof of identity of the beneficiary or policyholder will be required.
      •    Account details of beneficiary(ies)will be required.`,
      `Payment Methods:
      •    Premium payment methods available are standing orders, bank transfer, and direct debit transfer.
      •    Cash payments should only be paid to an identified representative at any AIICO office. Cash payments should never be made to an agent!`
    ],
    others: {
      benefits: [
        "Cover/Benefit Size : The annual limit on benefit is limited to a minimum of NGN 50,000 and a maximum of NGN 2,000,000",
        "Benefit : Daily Cash Benefit subject to a minimum of 3 days of hospitalization"


      ]
    },
    images: {
      banner: "./assets/images/extra/hospital-cash-banner.jpg",
      form: "./assets/images/extra/hospital-cash--form.jpg"
    }
  },
  {
    id: "085117b7-dd33-42d1-9f92-aefc9b2ca402",
    summary:
      `
    Required Documentation:
•    Completed Proposal Form
•    Proof of identity of the Proposed. One of the following is acceptable: Driver’s License, Voter’s Card , National Identity Card
•    Evidence of premium payment.
    `,
    premiums: [
      ` Annual Premium       / ₦16,000
      `,
      ` Semi-Annual Premium  / ₦8,600
      `,
      ` Quaterly Premium     / ₦4,500
      `,
      ` Monthly Premium      / ₦1,600
      `,
      `Claims Required Documentation:
      •    Original Copy of the Policy Document will be required.
      •    Proof of identity of the beneficiary or policyholder will be required.
      •    Account details of beneficiary(ies)will be required.`,
      `Payment Methods:
      •    Monthly premium should only be by Direct debit.
      •    Waiting period of 3 months for monthly payment mode.
      •    Premium includes ₦1000 towards Hospital cash policy`
    ],
    others: {
      benefits: [
        "Limit Per Claim : 10,000 per hospitalisation up to a limit of 5 times in a year",
        "Benefit : Cash Benefit subject to a minimum of 3 days of hospitalization",
        "Evacuation Services",
        "Out-patient Services",
        "Laboratory & Radiological Services",
        "Diagnostics Services",
        "In-patient Services",
        "Obstetrics & Gynecology Services",
        "Neonatal/Pediatric services",
        "Surgical Services",
        "Annual Medical Screening at Designated centres"
      ]
    },
    images: {
      banner: "./assets/images/extra/hospital-cash-banner.jpg",
      form: "./assets/images/extra/hospital-cash--form.jpg"
    }
  }
];

export const HOMESLIDES = [
  {
    id: "slide1",
    description: "Are you worried about securing the future?",
    image: "slider-image1.jpg"
  },
  {
    id: "slide2",
    description: "Need to get a quote for your company?",
    image: "slider-image2.jpg"
  },
  {
    id: "slide3",
    description: "Life happens, secure your family today",
    image: "slider-image3.jpg"
  },
  {
    id: "slide4",
    description: "Would you like to explore more plans?",
    image: "slider-image4.jpg"
  }
];
