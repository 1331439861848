import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WomenHealthPlanRoutingModule } from './women-healthplan-routing.module';
import { WomenHealthPlanComponent } from './women-healthplan.component';
import { SharedModule } from '@app/shared/shared.module';
import { RavepaymentModule } from '@app/components/ravepay/ravepay.module';


@NgModule({
  declarations: [WomenHealthPlanComponent],
  imports: [
    SharedModule,
    CommonModule,
    WomenHealthPlanRoutingModule,
    RavepaymentModule
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class WomenHealthPlanModule{
}
