import { BaseService } from "@app/services/base.service";

export class InterswitchOptionsModel {
  public product_id: any;
  public pay_item_id: any;
  public amount: number;
  public txn_ref: string;
  public cust_id: string;
  public currency: any;
  public macKey: any;
  public hash: any;
  public redirect_url: string;
  public url: string;
  public name: string;
  public pay_item_name?: string;


  constructor(private baseService?: BaseService) {
    baseService = new BaseService();
    this.product_id = '';
    this.pay_item_id = 0;
    this.amount = 0;
    this.txn_ref = '';
    this.currency = 566;
    this.hash = '';
    this.cust_id = '';
    this.redirect_url = baseService.site_redirect_url,
    this.url = baseService.interswitchUrl,
    this.name = '';
    this.pay_item_name = '';
    this.macKey = '';
  }
}
