import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MotorRenewalComponent } from './motor-renewal.component';


const routes: Routes = [{ path: "", component: MotorRenewalComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MotorRenewalRoutingModule { }
