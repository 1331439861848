import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


import { SharedModule } from '@app/shared/shared.module';
import { KycFormComponent } from './kyc-form.component';
import { KycFormRoutingModule } from './kyc-form-routing.module';



@NgModule({
  declarations: [KycFormComponent],
  imports: [
    SharedModule,
    CommonModule,
    KycFormRoutingModule,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class KycFormModule{
}
