import { Injectable } from "@angular/core";
import { MONTHS } from '../shared/data';

@Injectable()
export class UtilityService {

  constructor() {
  }

  checkIfValueExistsInArr(arr, value, property) {
    return arr.some(el => el[property] === value);
  };

  isArrayEmpty(arr: Array<any>) {
    return !arr || arr.length === 0;
  }

  isObjectEmpty(obj: Object) {
    // eslint-disable-next-line no-unused-vars
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return true;
  };

  sortObject(obj: Object) {
    const result = {};
    Object.keys(obj)
      .sort()
      .forEach(key => result[key] = obj[key]);

    return result;
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  formateDate = (value?, altFormat?) => {
    const date = value ? new Date(value) : new Date();
    const year = date.getFullYear();
    const month = altFormat ? MONTHS[date.getMonth()] : date.getMonth() + 1;
    const day = date.getDate();
    return altFormat ? `${day}-${month}-${year}` : `${year}-${month}-${day}`;
  }

  formatAmount = (value: any) => {
    if (value) {
      return `${value}`.replace(/\d{1,3}(?=(\d{3})+$)/g, s => `${s},`);
    }

    return value;
  };


  formatAmountToString(val: any) {
    try {
      if (val) {
        val = this.formatAmountToNumber(val);
        return val = (parseInt(val.toString().replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US');
      }
    }
    catch (e) { }
  }

  formatAmountToNumber(val: any) {
    if (val)
      return val = typeof val == 'number' ? val : parseFloat(val.replace(/,/g, ''));
  }

}
