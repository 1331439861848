import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, Inject, NgZone, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss']
})
export class ErrorHandlerComponent implements OnInit {
  @Input() showError: boolean;
  @Input() errorStatus: any;
  @Input() errorMessage: string;
  @Output() closedModal = new EventEmitter<any>();
  @Input() showCloseModal: boolean;


  constructor(    private ngZone: NgZone, @Inject(DOCUMENT)
  private document: Document,
  private renderer: Renderer2,
) { }

  ngOnInit() {
  }

  goHome() {
    location.href = "/";
  }

  closeModal() {

    this.closedModal.emit();

    this.ngZone.run(() => {
      const elem = this.document.querySelector('.errorModal');
      this.renderer.removeChild(this.document.body, elem);
      // this.renderer.addClass(this.document.body, 'overflow-auto');
      // this.router.navigateByUrl("result/flutterwave");
    });

  }

}
