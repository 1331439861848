import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HospitalCashService {

  constructor(public baseService: BaseService, private _http: HttpClient) {
    this.baseService.http = _http;
  }

  postHospitalCashSchedule(insurance): Observable<any> {

    // const content_ = JSON.stringify(insurance);
    const url = `${this.baseService.baseUrl}/HospitalCashProductService/PostHospitalCashSchedule`

    return this.baseService.http
      .post<any>(url, insurance, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }


  //FINALIZED METHOD
  
}
