import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class AIICOeBuisnessInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        if (!request.headers.has('Access-Control-Allow-Origin')) {
            request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });
        }

        if (!request.headers.has('Access-Control-Allow-Methods')) {
            request = request.clone({ headers: request.headers.set('Access-Control-Allow-Methods', 'GET, POST') });
        }

        if (!request.headers.has('Access-Control-Allow-Headers')) {
            request = request.clone({ headers: request.headers.set('Access-Control-Allow-Headers', '*') });
        }

        if (!request.headers.has('Access-Control-Expose-Headers')) {
            request = request.clone({ headers: request.headers.set('Access-Control-Expose-Headers', 'Content-Length, X-JSON') });
        }

        if (!request.headers.has('X-ApiKey')) {
            request = request.clone({ headers: request.headers.set('X-ApiKey', 'oiuiiusdiTUIA893208KKYUEFWEU7WQiasugsif89r73+0++==AYUEIOK?/LKOAUD124Aasswetywiugjiuyoriwhrwqur81f5ef1344a860983682&(*))*^29287ca63d5b4cf41') });
        }


    return next.handle(request);
  }
}
