export class Plan {
    public desc: string;
    public id: string;
    public image: string;
    public name: string;

    constructor() {
        this.desc = "";
        this.id = "";
        this.image = "./assets/images/insurance/auto/auto.png";
        this.name = "";
    }
}
