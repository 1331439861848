import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WomenHealthPlanComponent } from './women-healthplan.component';


const routes: Routes = [{ path: "", component: WomenHealthPlanComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WomenHealthPlanRoutingModule { }
