import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Plan } from "src/app/models/plan";
import { AUTO_INSURANCE, PROPERTY_INSURANCE } from "src/app/shared/data";

@Component({
  selector: "app-plan-cards",
  templateUrl: "./plan-cards.component.html",
  styleUrls: ["./plan-cards.component.scss"]
})
export class PlanCardsComponent implements OnInit, OnChanges {
  @Input() planName: string;

  planData: BehaviorSubject<Array<Plan>>;

  constructor() {
    this.planData = new BehaviorSubject([]);
  }

  ngOnInit() {
    this.setPlanData(this.planName);
  }

  ngOnChanges(changes: SimpleChanges) {

    for (const property in changes) {
      if (property === "planName") {
        this.setPlanData(changes[property].currentValue);
      }
    }
  }

  getPlanData(data: Array<any>) {
    const array = data.map(item => {
      const obj = new Plan();
      obj.id = item.id;
      obj.name = item.name;
      obj.desc = item.summary;
      obj.image = item.image;
      return obj;
    });
    return this.planData.next(array);
  }

  setPlanData(property) {
    if (property === "auto") {
      return this.getPlanData(AUTO_INSURANCE);
    }

    if (property === "property") {
      return this.getPlanData(PROPERTY_INSURANCE);
    }

    return this.planData.next([]);
  }

}
