import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { catchError, timeout } from 'rxjs/operators';
// import { HttpParams } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RenewalService {
  profile: any;
  renewalDetails: any;

  constructor(private baseService: BaseService, private http: HttpClient) { 
    this.baseService.http = http;
  }

  GetTravelTermAssurance(policyNumber: string): Observable<any> {
    //TRA-9BED15
    const url = `${this.baseService.baseUrl}/TravelProductService/GetTravelTermAssurance?certificateOrPhoneNo=${policyNumber}`;
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetLifePolicyRenewalDetails(policyNumber: string): Observable<any> {
    const url = `${this.baseService.baseUrl}/LifeRenewalService/GetLifePolicyRenewalDetails?policyNo=${policyNumber}`;
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetLoanListRepaymentDetails(policyNumber: string): Observable<any> {
    const url = `${this.baseService.baseUrl}/LifeRenewalService/GetLoanListRepaymentDetails?policyNo=${policyNumber}`;
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetLoanRepaymentDetailsWithRef(policyNumber: string, ref: string): Observable<any> {
    const url = `${this.baseService.baseUrl}/LifeRenewalService/GetLoanRepaymentDetails?policyNo=${policyNumber}&refNumber=${ref}`;
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetNonLifePolicyRenewalDetails(policyNumber: string): Observable<any> {
    const url = `${this.baseService.baseUrl}/NonLifeRenewalAppSrevice/GetNonLifeRenewalPolicy?polNo=${policyNumber}`;
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostLifeRenewalSchedule(schedule): Observable<any> {

    const url = `${this.baseService.baseUrl}/LifeRenewalService/PostLifeRenewalSchedule`

    return this.baseService.http
      .post<any>(url, schedule, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostNonLifeRenewalSchedule(schedule): Observable<any> {

    const url = `${this.baseService.baseUrl}/NonLifeRenewalAppSrevice/PostNonLifeRenewalScheduleAsync`

    return this.baseService.http
      .post<any>(url, schedule, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetClientPolicies(policyNumber: string): Observable<any> {

    const url = `${this.baseService.baseUrl}/ClientService/GetClientPolicies?policyNo=${policyNumber}`;

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetAgentDetails(agentCode: string): Observable<any> {

    const url = `${this.baseService.baseUrl}/Intermediary/GetAgentDetails?agentCode=${agentCode}`;

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetClientInfoByEmail(clientEmail: string): Observable<any> {

    const url = `${this.baseService.baseUrl}/ClientService/GetClientInfoByEmail?clientEmail=${clientEmail}`;

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostClientProfiling(profile): Observable<any> {

    const url = `${this.baseService.baseUrl}/ClientService/PostClientProfilling`

    return this.baseService.http
      .post<any>(url, profile, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostAgentProfiling(profile): Observable<any> {

    const url = `${this.baseService.baseUrl}/Intermediary/PostAgentProfilling`

    return this.baseService.http
      .post<any>(url, profile, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  // ValidateRenewalByPolicy(policyNumber: string) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'X-ApiKey': '1Q2WERTS345-=YU()HJLOU567$VBJSGHakusguskw88YR8AVVUSG8SDGA7'
  //     })
  //   };

  //   const url = "https://flexsureadmintest.azurewebsites.net/api/services/app/Renewal/ValidateRenewalByPolicy?policyNumber=NCSP/IB/2017/077067";

  //   return this.http.get(url, httpOptions);
  // }
}
