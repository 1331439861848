import { AfterViewInit, Component, EventEmitter, Inject, NgZone, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import {TermLifeService} from "src/app/services/termlife-service";


@Component({
  selector: 'app-termlife',
  templateUrl: './termlife.component.html',
  styleUrls: ['./termlife.component.scss']
})
export class TermlifeComponent implements OnInit{

  loading!: boolean | undefined;
  errorStatus$ = new BehaviorSubject('');
  errorMessage$ = new BehaviorSubject('');
  error!: boolean | undefined;
  extraInfo$: BehaviorSubject<any>;
  transactionRef: string;
  outputMessage$ = new BehaviorSubject('');
  outputMessageDescription$ = new BehaviorSubject('');
 

  constructor(
    private route: ActivatedRoute,
    private _termAssurance:TermLifeService

  ) {  }

  ngOnInit() {
    this.transactionRef = this.route.snapshot.queryParamMap.get('transRef');
    this.loading = true;
    this.error = false;
    if(this.transactionRef === null||this.transactionRef ===''||!this.transactionRef.includes('-')) {
      this.loading=false;
      this.error=true;
      this.outputMessage$.next('Invalid Transaction Reference');
      this.outputMessageDescription$.next('Failed');
      return;
    } 
    this._termAssurance.GetOptOut(this.transactionRef) .subscribe(res => {
      this.loading=false;
      this.error=true;
      if (res==true) {
        this.outputMessage$.next('Termlife Update Successful');
        this.outputMessageDescription$.next('You have successfully opted out from the term life assurance cover');
      } else {
        this.outputMessage$.next('Termlife Update');
        this.outputMessageDescription$.next('You already opted out from the term life assurance cover');
      }

    }, err => {
      this.loading=false;
      this.error=true;
      this.outputMessage$.next("Failed");
      this.outputMessageDescription$.next(err.error.error.message);
    }); 
  }


}
