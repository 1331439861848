import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TermLifeService {

    constructor(public baseService: BaseService, private _http: HttpClient) {
    this.baseService.http = _http;
  }

  GetOptOut(transactionRef): Observable<any> {

    //const url = `${this.baseService.baseUrl}/TermAssuranceService/OptOut`
    const url = `${this.baseService.baseUrl}/TermAssuranceService/OptOut?transactionRef=${transactionRef}`

    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetTransactionBeneficiary(transactionRef: string): Observable<any> {

    const url = `${this.baseService.baseUrl}/TermAssuranceService/GetTransactionBeneficiary?transactionRef=${transactionRef}`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  postTermLifeCover(clientId: string | null | undefined, transactionRef: string | null | undefined, beneficiaryActive: boolean, dFullName: string | null | undefined, dPhoneNumber: string | null | undefined, dateOfBirth: Date | null | undefined): Observable<any> {
    const url = `${this.baseService.baseUrl}/PersonalAccidentProductService/TermLifeCover?clientId=${clientId}&transactionRef=${transactionRef}&beneficiaryActive=${beneficiaryActive}&dFullName=${dFullName}&dPhoneNumber=${dPhoneNumber}&dateOfBirth=${dateOfBirth.toJSON()}`
    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  PostPersonalAccidentCertificate(transactionRef): Observable<any> {

    const url = `${this.baseService.baseUrl}/PersonalAccidentProductService/PostPersonalAccidentCertificate?tr=${transactionRef}`

    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }
// string dRelationship = "", string dAddress = "")
  postWomenPlanTermLifeCover(clientId: string | null | undefined, transactionRef: string | null | undefined, beneficiaryActive: boolean, dFullName: string | null | undefined,dPhoneNumber: string | null | undefined,dRelationship: string | null | undefined,dAddress: string | null | undefined): Observable<any> {
    const url = `${this.baseService.baseUrl}/HospitalCashProductService/TermLifeCover?clientId=${clientId}&transactionRef=${transactionRef}&beneficiaryActive=${beneficiaryActive}&dFullName=${dFullName}&dPhoneNumber=${dPhoneNumber}&dRelationship=${dRelationship}&dAddress=${dAddress}`
    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }




//PostPersonalAccidentCertificate
  
}
