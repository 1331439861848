import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { AuthService } from 'src/app/services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

  @Input('isVisible') isVisible: boolean;
  @Output() closedModal = new EventEmitter<any>();
  validateForm: FormGroup;
  loading$: BehaviorSubject<boolean>;
  error$: BehaviorSubject<boolean>;
  errorStatus$: BehaviorSubject<string>;
  errorMessage$: BehaviorSubject<string>;

  constructor(private fb: FormBuilder, private router: Router, public authService: AuthService, private message: NzMessageService) {
    this.loading$ = new BehaviorSubject(false);
    this.errorStatus$ = new BehaviorSubject('');
    this.errorMessage$ = new BehaviorSubject('');
    this.error$ = new BehaviorSubject(false);
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      userName: ["", [Validators.required]],
      password: ["", [Validators.required]],
      remember: [false]
    });
  }

  handleCancel(): void {
    this.closedModal.emit();
  }

  submitForm(value: any): void {
    // tslint:disable-next-line: forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }

    const id = this.message
      .loading('Loading', { nzDuration: 0 }).messageId;

    this.authService.login(value.userName, value.password).subscribe(
      response => {
        // console.log(response);
        this.message.remove(id);
        this.message.create('success', 'Logged in successfully');

        // save token in localstorage
        // const expirationDate = new Date(response.result.expireInSeconds * 1000);
      },
      error => {
        this.message.remove(id);

        if (error) {
          return this.message.create('error', error.error.error.details);
        }

        this.message.create('error', 'Network Error!');

      }
    );

  }

  closeErrorModal() {
    this.error$.next(false);
  }

}
