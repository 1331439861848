import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CriticareRoutingModule } from './criticare-routing.module';
import { CriticareComponent } from './criticare.component';
import { SharedModule } from '@app/shared/shared.module';
import { RavepaymentModule } from '@app/components/ravepay/ravepay.module';


@NgModule({
  declarations: [CriticareComponent],
  imports: [
    SharedModule,
    CommonModule,
    CriticareRoutingModule,
    RavepaymentModule
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class CriticareModule{
}
