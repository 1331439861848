import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { TransactionModel } from 'src/app/models/transaction';


@Component({
  selector: 'app-rave-result',
  templateUrl: './rave-result.component.html',
  styleUrls: ['./rave-result.component.scss']
})
export class RaveResultComponent implements OnInit {
  @Input() transactionData: TransactionModel;
  @Input() transactionStatus: string;
  @Input() transactionDescription: string;
  @Input() isVisible: boolean;

  constructor() { }

  ngOnInit() {
  }

  handleCancel() {
    console.log('cancelled by user')
  }

  joinArr(arr) {
    if (arr) {
      return arr.join(', ');
    }

    return 'N/A';
  }

}
