import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NonlifeRenewalRoutingModule } from './nonlife-renewal-routing.module';
import { NonlifeRenewalComponent } from './nonlife-renewal.component';
import { SharedModule } from '@app/shared/shared.module';
import { RavepaymentModule } from '@app/components/ravepay/ravepay.module';


@NgModule({
  declarations: [NonlifeRenewalComponent],
  imports: [
    SharedModule,
    CommonModule,
    NonlifeRenewalRoutingModule,
    RavepaymentModule
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class NonlifeRenewalModule{
}
