import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ProfilingModalComponent } from "../profiling-modal/profiling-modal.component";
import { ProductsService } from 'src/app/services/products.service';
import { UtilityService } from 'src/app/services/utility.service';


@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"]
})
export class NavComponent implements OnInit {

  @ViewChild(ProfilingModalComponent, { static: false })

  private modalComponent: ProfilingModalComponent;
  

  public productsByCategories$: BehaviorSubject<Object>;
  public categories$: BehaviorSubject<Array<string>>;

  isSticky: boolean;
  isSignupVisible = false;
  isLoginVisible = false;
  validateForm: FormGroup;
  loading$: any;

  isValidateVisible = false;
  modalContent: any;
  isVisible = false;

  constructor(private fb: FormBuilder, public productService: ProductsService, public utilityService: UtilityService) {
    this.isSticky = false;
    this.validateForm = this.fb.group({
      validateNumber: ["", [Validators.required]]
    });
    this.productsByCategories$ = new BehaviorSubject({});
    this.categories$ = new BehaviorSubject([]);
  }

  ngOnInit(): void {
    this.productService.productsByCategories$.subscribe(data => {
      if (!this.utilityService.isObjectEmpty(data)) {
        this.productsByCategories$.next(data);
        this.categories$.next(Object.keys(data));
      }
    });
    //remove agency banking
    
  }


  @HostListener("window:scroll", [""])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 100;
  }

  handleSignUpCancel(): void {
    this.isSignupVisible = false;
  }



  showSignUpModal(): void {
    this.isSignupVisible = true;
  }

  handleCancel() {
    this.modalComponent.handleCancel();
  }

  showProfileModal(value) {
    this.modalComponent.showModal(value);
  }

  showTravelModal() {
    // this.modalComponent.showModal();
  }

  handleTravelCancel() {
    this.modalComponent.handleCancel();
  }

  showTermLifeModal() {
    // this.modalComponent.showModal();
  }

  handleTermLifeCancel() {
    this.modalComponent.handleCancel();
  }

  showGeneralInsuranceModal() {
    // this.modalComponent.showModal();
  }

  handleGeneralInsuranceCancel() {
    this.modalComponent.handleCancel();
  }

  showLoginModal() {
    this.isLoginVisible = true;
  }

  handleLoginCancel(): void {
    this.isLoginVisible = false;
  }

  submitForm(value: any): void {
    // tslint:disable-next-line: forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    this.handleCancel();
  }

  handleCalculatorCancel(): void {
    this.isVisible = false;
  }

  showModal(value) {
    this.modalComponent.showModal(value);
  }

  scrollToDiv(el: HTMLElement) {
    el.scrollIntoView();
  }

  handleValidateCancel(): void {
    this.isValidateVisible = false;
  }

  showValidateModal(value): void {
    this.modalContent = value;
    this.isValidateVisible = true;
  }

}
