import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user$: BehaviorSubject<any>;

  baseurl = "https://flexadminbeta.azurewebsites.net";

  constructor(private baseService: BaseService) {

  }

  login(username: string, password: string): Observable<any> {

    const url = `${this.baseurl}/api/TokenAuth/Authenticate`;
    const data = {
      "userNameOrEmailAddress": username,
      "password": password,
      "twoFactorVerificationCode": "string",
      "rememberClient": true,
      "twoFactorRememberClientToken": "string",
      "singleSignIn": true,
      "returnUrl": "string"
    };

    return this.baseService.http
      .post<any>(url, data, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  refreshLogin(refreshToken: string) {
    const url = `${this.baseurl}/api/TokenAuth/RefreshToken?refreshToken=${refreshToken}`;

    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  logout() {
    const url = `${this.baseurl}/api/TokenAuth/LogOut`;

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      ).subscribe(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
      });
  }

  checkAuthTimeout(expirationTime) {
    setTimeout(() => {
      this.logout();
    }, expirationTime);
  };

  authCheckState(): boolean {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }

    const expirationDate = new Date(localStorage.getItem('expirationDate'));

    console.log(expirationDate);

    if (expirationDate <= new Date()) {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationDate');
      return false;
    }

    return true;
  }

  getCurrentLoginInfo() {
    const url = `${this.baseurl}/api/services/app/Session/GetCurrentLoginInformations`;
    const headers = this.baseService.headers;
    const token = localStorage.getItem('token');
    headers.append('Authorization', token);

    return this.baseService.http
      .get<any>(url, { headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }
}
