import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


import { SharedModule } from '@app/shared/shared.module';
import { MotorProposalComponent } from './motor-proposal.component';
import { MotorProposalRoutingModule } from './motor-proposal-routing.module';


@NgModule({
  declarations: [MotorProposalComponent],
  imports: [
    SharedModule,
    CommonModule,
    MotorProposalRoutingModule,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class MotorProposalModule{
}
