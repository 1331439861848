import { Component, OnInit } from "@angular/core";
import { ProductsService } from './services/products.service';
import { UtilityService } from './services/utility.service';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "aiico-ebusiness";
  loading$: BehaviorSubject<boolean>;
  error$: BehaviorSubject<boolean>;
  errorStatus$: BehaviorSubject<string>;
  errorMessage$: BehaviorSubject<string>;

  constructor(private router: Router, public productsService: ProductsService, public utilityService: UtilityService) {
    this.loading$ = new BehaviorSubject(false);
    this.errorStatus$ = new BehaviorSubject('');
    this.errorMessage$ = new BehaviorSubject('');
    this.error$ = new BehaviorSubject(false);

    // scroll to top
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {
    this.error$.next(false);
    this.getProducts();
  }

  getProducts() {
    this.loading$.next(true);

    this.productsService.getProducts().subscribe(data => {
      // get all products
      if(data && data.success) {

      var activeProducts: any[] =   data.result as any[];
      activeProducts = activeProducts.filter((x) => x.id !== '684dcea8-4ec0-ea11-a336-005056a00da2' && x.id !== 'ef8cbc13-d30e-eb11-a34d-005056a00da2' && x.id !== '897a91f5-6225-e811-a2cc-005056a08881');


      this.productsService.allProducts$.next(activeProducts);
      localStorage.setItem('allProducts', JSON.stringify(activeProducts));

      // get products by category
      const categories = {};
      activeProducts.map(product => {
        const category = product.productCategory.toLowerCase();
        if (!Object.keys(categories).includes(category)) {
          categories[category] = [product];
        } else {
          categories[category] = [...categories[category], product];
        }
      });

      this.productsService.productsByCategories$.next(this.utilityService.sortObject(categories));
    }
      this.loading$.next(false);
    }, error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error$.next(true);
      this.loading$.next(false);
    });
  }
}
