import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilingModalComponent } from '@app/components/profiling-modal/profiling-modal.component';
import { ProductsService } from '@app/services/products.service';
import { UtilityService } from '@app/services/utility.service';
import { HOMESLIDERCONFIG, PLANICONS, HOMESLIDES } from '@app/shared/data';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-agent-referral',
  templateUrl: './agent-referral.component.html',
  styleUrls: ['./agent-referral.component.scss']
})
export class AgentReferralComponent implements OnInit {

  @ViewChild(ProfilingModalComponent, { static: false })
  private modalComponent: ProfilingModalComponent;

  public productsByCategories$: BehaviorSubject<Object>;
  public categories$: BehaviorSubject<Array<string>>;
  agentCode!: string | undefined;

  slideConfig = HOMESLIDERCONFIG;
  planIcons = PLANICONS;
  slides = HOMESLIDES;
  isValidateVisible = false;
  isSticky = true;

  constructor(private route: ActivatedRoute,
    private router: Router,
 public productService: ProductsService, public utilityService: UtilityService) {
    this.productsByCategories$ = new BehaviorSubject({});
    this.categories$ = new BehaviorSubject([]);
  }

  ngOnInit() {

    this.agentCode = this.route.snapshot.queryParamMap.get('ac');
    if(this.agentCode !=='' || this.agentCode.length > 0) {
      localStorage.setItem('agent_code', this.agentCode);
    }

    this.productService.productsByCategories$.subscribe(data => {
      if (!this.utilityService.isObjectEmpty(data)) {
        this.productsByCategories$.next(data);
        this.categories$.next(Object.keys(data));
      }
    });
  }

  handleCancel() {
    this.modalComponent.handleCancel();
  }

  showModal(value) {
    this.modalComponent.showModal(value);
  }

  scrollToDiv(el: HTMLElement) {
    el.scrollIntoView();
  }

  handleValidateCancel(): void {
    this.isValidateVisible = false;
  }

  showValidateModal(): void {
    this.isValidateVisible = true;
  }

}
