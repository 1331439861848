import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermlifeBeneficiaryRoutingModule } from './termlife-beneficiary-routing.module';
import { TermlifeBeneficiaryComponent } from './termlife-beneficiary.component';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [TermlifeBeneficiaryComponent],
  imports: [
    SharedModule,
    CommonModule,
    TermlifeBeneficiaryRoutingModule
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class TermlifeBeneficiaryModule{
}
