import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";
import { BAComponent } from "./pages/bank-assurance/ba.component";

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
      },
      {
        path: "hospital-cash",
        loadChildren: () => import('./pages/hospital-cash/hospital-cash.module').then(m => m.HospitalCashModule)
      },
      {
        path: "criticare",
        loadChildren: () => import('./pages/criticare/criticare.module').then(m => m.CriticareModule)
      },
      {
        path: "plan/:category/:productId",
        loadChildren: () => import('./pages/plan/plan.module').then(m => m.PlanModule)
      },
      {
        path: "healthplan/:productId",
        loadChildren: () => import('./pages/healthplan/healthplan.module').then(m => m.HealthPlanModule)
      },
      {
        path: "shop/:category/:subProductId",
        loadChildren: () => import('./pages/shop/shop.module').then(m => m.ShopModule)
      },
      {
        path: "register/:category/:subProductId",
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: "payment/:category",
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: "policy-renewal",
        loadChildren: () => import('./pages/policy-renewal/policy-renewal.module').then(m => m.PolicyRenewalModule)
      },
      {
        path: "renewal/life",
        loadChildren: "./pages/lifelink/lifelink.module#LifelinkModule"
      },
      {
        path: "renewal/loan",
        loadChildren: "./pages/loanlink/loanlink.module#LoanlinkModule"
      },
      {
        path: "corporate-savings",
        loadChildren: () => import('./pages/corporate-savings/corporate-savings.module').then(m => m.CorporateSavingsModule)
      },
      {
        path: "personal-accident",
        loadChildren: () => import('./pages/personal-accident/personal-accident.module').then(m => m.PersonalAccidentModule)
      },
      {
        path: "client-profile",
        loadChildren: () => import('./pages/client-profile/client-profile.module').then(m => m.ClientProfileModule)

      },
      {
        path: "agent-profile",
        loadChildren: () => import('./pages/agent-profile/agent-profile.module').then(m => m.AgentProfileModule)
      },
      {
        path: "BA",
        loadChildren: () => import('./pages/bank-assurance/ba.module').then(m => m.BAModule)
      },
      {
        path: "BA/:it/:mobile",
        loadChildren: () => import('./pages/bank-assurance/ba.module').then(m => m.BAModule)
      },
      {
        path: "agency",
        loadChildren: () => import('./pages/agent-referral/agent-referral.module').then(m => m.AgentReferralModule)
      },
      {
        path: "result/:paymentChannel",
        loadChildren: () => import('./pages/result/result.module').then(m => m.ResultModule)
      },
      {
        path: "term-life/:transRef",
        loadChildren: "./pages/term-life/termlife.module#TermlifeModule"
      },
      {
        path: "termassurance/optout",
        loadChildren: "./pages/term-life/termlife.module#TermlifeModule"
      },
      {
        path: "termassurance/updatebeneficiary",
        loadChildren: "./pages/termlife-beneficiary/termlife-beneficiary.module#TermlifeBeneficiaryModule"
      },
      {
        path: "auto/autorenewaldetails",
        loadChildren: "./pages/motor-renewal/motor-renewal.module#MotorRenewalModule"
      },
      {
        path: "nonlife/renewaldetails",
        loadChildren: () => import('./pages/nonlife-renewal/nonlife-renewal.module').then(m => m.NonlifeRenewalModule)
      },
      {
        path: "auto/autoproposalform",
        loadChildren: () => import('./pages/motor-proposal/motor-proposal.module').then(m => m.MotorProposalModule)
      },
      {
        path: "auto/autoclaimform",
        loadChildren: () => import('./pages/motor-claim/motor-claim.module').then(m => m.MotorClaimModule)
      },
      {
        path: "kyc-form",
        loadChildren: () => import('./pages/kyc-form/kyc-form.module').then(m => m.KycFormModule)
      },
      {
        path: "hospital-cash/WemaWomenHealthPlan",
        loadChildren: "./pages/women-healthplan/women-healthplan.module#WomenHealthPlanModule"
      },
       {
        path: "HospitalCash/WemaWomenHealthPlan",
        redirectTo: "healthplan/085117b7-dd33-42d1-9f92-aefc9b2ca402"
      }


    ]
  },
  // {
  //   path: "BA",
  //   component: BAComponent
  // },
  {
    path: "404",
    component: NotFoundComponent
  },
  { path: "**", redirectTo: "/404" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
