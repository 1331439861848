import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"]
})
export class MainLayoutComponent implements OnInit {

  isMobile!: string | undefined;
  isMobileOn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private router: Router) {
    this.routeEvent(this.router);
  }

  ngOnInit() {
    this.isMobile = localStorage.getItem("ba_mobile");
    if(this.isMobile !=='' || this.isMobile.length > 0) {
      this.isMobileOn$.next(true)
    }
  }

  routeEvent(router: Router) {
    router.events.subscribe((url: any) => {
      const navigation = document.querySelector(".navigation");
      if (navigation) {
        if (router.url === "/") {
          navigation.classList.add("bg-transparent");
        } else {
          navigation.classList.remove("bg-transparent");
        }
      }
    });
  }

}
