import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgentReferralRoutingModule } from './agent-referral-routing.module';
import { AgentReferralComponent } from './agent-referral.component';


@NgModule({
  declarations: [AgentReferralComponent],
  imports: [
    CommonModule,
    AgentReferralRoutingModule
  ]
})
export class AgentReferralModule { }
