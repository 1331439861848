import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { NgZorroAntdModule, NzInputNumberModule } from "ng-zorro-antd";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzResultModule } from 'ng-zorro-antd/result';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProductCardComponent } from "../components/product-card/product-card.component";
import { CheckComponent } from "../components/check/check.component";
import { ProfilingModalComponent } from "../components/profiling-modal/profiling-modal.component";
import { ErrorHandlerComponent } from '../components/error-handler/error-handler.component';
import { TermCertificateComponent } from '../components/term-certificate/term-certificate.component';
import { LoginComponent } from '../components/login/login.component';
import { InterswitchComponent } from '../components/interswitch/interswitch.component';
import { RaveResultComponent } from '../components/rave-result/rave-result.component';
import { TravelValidationModalComponent } from "@app/components/travel-validation-modal/travel-validation-modal.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NzSelectModule,
    NzResultModule,
    NgZorroAntdModule,
    ReactiveFormsModule
  ],
  declarations: [ProductCardComponent, CheckComponent, ProfilingModalComponent, TravelValidationModalComponent, LoginComponent, ErrorHandlerComponent, TermCertificateComponent, InterswitchComponent, RaveResultComponent],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzResultModule,
    NgZorroAntdModule,
    ProductCardComponent,
    LoginComponent,
    CheckComponent,
    ProfilingModalComponent,
    TravelValidationModalComponent,
    NzInputNumberModule,
    ErrorHandlerComponent,
    TermCertificateComponent,
    InterswitchComponent,
    RaveResultComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
