import { Component, OnInit, Input } from "@angular/core";
import { Product } from "src/app/models/product";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";


@Component({
  selector: "app-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"]
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product;

  constructor(private sanitizer: DomSanitizer, private router: Router) { }

  ngOnInit() {
  }

  redirect(product) {
    if (this.doesObjectPropertyExist(product, "packageId")) {
      return this.router.navigate(["/" + product.url, { id: product.id, packageId: product.packageId }]);
    }

    if (this.doesObjectPropertyExist(product, "id")) {
      return this.router.navigate(["/" + product.url, product.id]);
    }

    return this.router.navigate(["/" + product.url]);
  }

  doesObjectPropertyExist(object, property) {
    return (object.hasOwnProperty(property) && object[property] !== "" && object[property]);
  }

  sanitizeImage(image) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

}
