import { Component, EventEmitter, Inject, NgZone, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ProductsService } from 'src/app/services/products.service';
import { UtilService } from 'src/app/services/util.service';
import { UtilityService } from 'src/app/services/utility.service';
import { RenewalService } from 'src/app/services/renewal.service';
import { InsuranceVm } from 'src/app/models/insurance';
import { MotorProductService } from 'src/app/services/motor-product.service';
import {  MotorScheduleRequest,ProductSubClassCoverTypesModelFinal } from '@shared/service-proxies/service-proxies';
import { InterswitchOptionsModel } from "@app/models/interswitchOptions";
import { RaveOptionsModel } from "@app/models/raveOptions";
import { sha512 } from "js-sha512";
import { DOCUMENT } from "@angular/common";
import differenceInHours from "date-fns/differenceInHours";
import { HospitalCashService } from "@app/services/hospital-cash-service";
import { TransactionModel } from "@app/models/transaction";
import { PaymentService } from "@app/services/payment.service";
import { differenceInCalendarDays } from "date-fns/esm";

@Component({
  selector: 'app-motor-proposal',
  templateUrl: './motor-proposal.component.html',
  styleUrls: ['./motor-proposal.component.scss']
})
export class MotorProposalComponent implements OnInit {

  policyNumber:string;
  notification:string;
 
  
  confirmDetails!: boolean | undefined;
  productDetailsDisabled: BehaviorSubject<boolean>;
  dateOfBirth!: Date | undefined;
  sumAssured?: number | undefined;


  today = new Date();
  current$: BehaviorSubject<number>;
  date = null; // new Date();
  selectedValue$: BehaviorSubject<string>;
  isVisible = false;

  selectedOption: any;
  options$: BehaviorSubject<Array<object>>;
 
  formatterNaira = (value: number) => `NGN ${value}`;
  parserNaira = (value: string) => value.replace("NGN ", "");

  planCategory$: BehaviorSubject<string>;
  subProductId$: BehaviorSubject<string>;
  selectedPlan$: BehaviorSubject<any>;
  loading!: boolean | undefined;
  errorStatus$ = new BehaviorSubject('');
  errorMessage$ = new BehaviorSubject('');
  error!: boolean | undefined;
  extraInfo$: BehaviorSubject<any>;
  titles = [];
  genders = [];
  insurance: InsuranceVm;
  transactionData$ = new BehaviorSubject(new TransactionModel());

  transactionStatus$: BehaviorSubject<string>;
  transactionDescription$: BehaviorSubject<string>;
  interswitchOptions: InterswitchOptionsModel;



  formErrors$ = new BehaviorSubject([]);
  cat: any;
  countries: any;
  clientCats: any;
  travelPlanObj: { subClassCoverTypes: { productName: string; subClassName: string; }; };
  errorArray: any[];
  caption: string;
  yearsOfDriving: any;

 
  beneficiaryValid: boolean;
  proposalConfirm:boolean;
  currentPosition: number;

  showFlutterwavePayment: boolean = false;

  paymentOption = '';
  raveOptions: RaveOptionsModel;
  transactionRef: string;

  isAgentCodeValid: boolean = false;
  agentCodeValidationResponse='';

  constructor(
    public renewalService: RenewalService,
    public utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone, @Inject(DOCUMENT)
    private document: Document,
    private renderer: Renderer2,
    public productService: ProductsService,
    public utilityService: UtilityService,
    public motorService: MotorProductService,
    public paymentService: PaymentService,
    ) {
   
      

    this.current$ = new BehaviorSubject(0);
    this.extraInfo$ = new BehaviorSubject(new Object());
    this.insurance = new InsuranceVm();
    this.notification='';

  }


  ngOnInit() {
    


  }

  disabledDOB = (current: Date): boolean => {
    var date = new Date();
    var eighteenYearsAgo = date.getFullYear() - 18;
    date.setFullYear(eighteenYearsAgo)
    return differenceInCalendarDays(current, date) > 0;
  };


  postMotorProposal(){
    this.loading = true;
    this.error = false;
    //post to endpoint
    this.motorService.PostAutoProposal(this.insurance).subscribe(res => {
      if (res) {
        if(res.result==true){
        
          this.notification='Auto proposal successfully submitted. A copy of the form has been submitted to your e-mail';

        }else{this.notification='Auto proposal submission failed';}
        this.error = false;
        this.loading = false;
      }
    }, error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error=true;
    }, () => this.loading=false)
  }





 


 






 






  formatAmountToString(val) {
    try {

      if (val) {
        val = this.formatAmountToNumber(val);
        return (parseInt(val.toString().replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US');
      }
    }
    catch (e) { }
  }

  formatAmountToNumber(val) {
    if (val)
      return val = typeof val == 'number' ? val : parseFloat(val.replace(/,/g, ''));
  }

 
/* 
  confirmFormFields() 
  {

      this.loading=true;
      this.error=false;
      this.motorService.PostMotorRenewalSchedule(this.productScheduleRequest).subscribe(res => {
        if (res) {

          this.error = false;
          this.loading = false;

          this.transactionRef = res.result.transactionRef;
          this.current$.next(this.current$.value + 1);
        }
      }, error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error=true;
      }, () => this.loading=false)
    } */

   

  isInputValid(name: string) {
    if (this.formErrors$.value.length) {
      return this.formErrors$.value[0].toLowerCase().includes(name);
    }
    return false;
  };





  joinArr(arr) {
    if (arr) {
      return arr.join(', ');
    }

    return 'N/A';
  }

  formatCurrency(value) {
    const num = parseFloat(value);

    if (num === num) {
      return `\u20A6${value}`;
    }

    return value;
  }

}
