export class KycFormUpdate {
  formType : string;
  indvidualKycType : boolean;
  corprateKycType : boolean;

  

  //individual
  firstName : string;
  lastName : string;
  gender : string;
  address : string;
  addressType : string;
  employerName : string; // to be changed to employerName
  email : string;
  tel : string; // to be changed to tel
  occupation : string;
  taxId : string;
  sourceOfWealth : string;
  nationality : string;
  isBusinessAddress : boolean; // new

  idMode : string; // to be changed to idMode
  idNo : string; // to be changed to idNo
  idIssuedDate : Date;
  idExpiryDate : Date;
  issuingCountry : string;
  dateOfBirth : Date;

  //account details
  accountName : string; // to be changed to accountName
  accountType : string; // to be changed to accountType
  accountNo : string; // to be changed to accountNo
  bankName : string;
  bankCode : string;
  bvn : string;
  bankBranch : string;
  transactionPurpose : string; // to be changed to transactionPurpose
  transactionDate : Date; // to be changed to transactionDate


  //corporate //S
  companyName : string;
  incorporatedNo : string;
  incorporationDate : Date;
  incorporationState : string;
  corprateWebsite : string; // to be changed to corporateWebsite
  corprateOccupation : string; // new
  corprateEmail : string; // new
  corprateTaxNumber : string; // new
  officeAddress : string;

  corprateAccountName : string; // new
  corprateAccountNumber : string; // new
  corprateBankName : string; // new
  corprateBankBranch : string; // new
  corprateBankCode : string; // new

  directors : DirectorData[];

  corprateCertificate : string; // to be changed to corporateCertificate
}

export class DirectorData {
  //all to be changed, adding director prefix to all of them
  directorFirstName : string;
  directorLastName : string;
  directorPosition : string;
  directorTel : string;
  directorIdMode : string; // changed to idMode
  directorIdNumber : string;
  directorIdIssuedDate : Date;
  directorIdExpiryDate : Date;
  directorOccupation : string;
  directorEmail : string;
  directorTitle : string;
  directorGender : string;
  directorAddress : string;

  constructor() {
    this.directorFirstName = '';
    this.directorLastName = '';
    this.directorPosition = '';
    this.directorTel = '';
    this.directorIdMode = '';
    this.directorIdNumber = '';
    this.directorIdIssuedDate = new Date();
    this.directorIdExpiryDate = new Date();
    this.directorOccupation = '';
    this.directorEmail = '';
    this.directorTitle = '';
    this.directorGender = '';
    this.directorAddress = '';
  }
}