export class RaveOptionsModel {
  public PBFPubKey: string;
  public customer_email: string;
  public customer_firstname: string;
  public customer_lastname: string;
  public custom_description: string;
  public customer_phone: string;
  public amount: number;
  public txref: string;
  public currency: string;



  constructor() {
    this.PBFPubKey = '';
    this.customer_email = 'aiicontact@aiicoplc.com';
    this.customer_firstname = '';
    this.customer_lastname = '';
    this.custom_description = '';
    this.amount = 0;
    this.customer_phone = '';
    this.txref = '';
    this.currency = 'NGN';
  }
}
