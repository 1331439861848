export class PaystackOptionsModel {
  public PBFPubKey: string;
  public subaccount: string;
  public email: string;
  public amount: number;
  public txref: string;
  public currency: string;
  public meta:any;



  constructor() {
    this.PBFPubKey = '';
    this.subaccount='';
    this.email = 'aiicontact@aiicoplc.com';
    this.amount = 0;
    this.txref = '';
    this.meta='';
    this.currency = 'NGN';
  }
}
