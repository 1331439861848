import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-term-certificate',
  templateUrl: './term-certificate.component.html',
  styleUrls: ['./term-certificate.component.scss']
})
export class TermCertificateComponent implements OnInit {

  @Input() visible: boolean = true;
  constructor() { }

  ngOnInit() {

  }

  showModal(val) {
    this.visible = val;
  }

  handleCancel() {
    this.visible = false;
  }
}
