import { AfterViewInit, Component, EventEmitter, Inject, NgZone, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ProductsService } from 'src/app/services/products.service';
import { UtilService } from 'src/app/services/util.service';
import { UtilityService } from 'src/app/services/utility.service';
import { RenewalService } from 'src/app/services/renewal.service';
import { InsuranceVm } from 'src/app/models/insurance';


import { EXTRA_INFO, YEARS_OF_DRIVING } from 'src/app/shared/data';
import { MotorProductService } from 'src/app/services/motor-product.service';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {  HospitalCashScheduleRequest, HospitalCashTransactionResponse, ProductSubClassCoverTypesModel, ProductSubClassCoverTypesModelFinal } from '@shared/service-proxies/service-proxies';
import { InterswitchOptionsModel } from "@app/models/interswitchOptions";
import { RaveOptionsModel } from "@app/models/raveOptions";
import { sha512 } from "js-sha512";
import { DOCUMENT } from "@angular/common";
import differenceInHours from "date-fns/differenceInHours";
import { HospitalCashService } from "@app/services/hospital-cash-service";
import { TransactionModel } from "@app/models/transaction";
import { PaymentService } from "@app/services/payment.service";
import {TermLifeService} from "@app/services/termlife-service";

@Component({
  selector: 'app-women-healthplan',
  templateUrl: './women-healthplan.component.html',
  styleUrls: ['./women-healthplan.component.scss']
})
export class WomenHealthPlanComponent implements OnInit, AfterViewInit {

  personalKYC!: boolean | undefined;
  productDetails!: boolean | undefined;
  productUpload!: boolean | undefined;
  productReview!: boolean | undefined;
  productPayment!: boolean | undefined;
  successfulPayment!: boolean | undefined;
  sectionOne!: boolean | undefined;
  sectionTwo!: boolean | undefined;
  sectionThree!: boolean | undefined;
  confirmDetails!: boolean | undefined;
  privacyPolicy!:boolean | undefined;
  dateOfBirth!: Date | undefined;
  sumAssured?: number | undefined;
  localGovtAreas = [];
  hospitals=[];



  productScheduleRequest!: HospitalCashScheduleRequest | undefined;
  productScheduleResponse!: HospitalCashScheduleRequest | undefined;
  selectedProductDetails!: ProductSubClassCoverTypesModelFinal | undefined;

  today = new Date();
  current$: BehaviorSubject<number>;
  date = null; // new Date();
  selectedValue$: BehaviorSubject<string>;
  isVisible = false;

  selectedOption: any;
  options$: BehaviorSubject<Array<object>>;
  validIds = [
    { label: "International Passport", value: "international-passport" },
    { label: "National ID", value: "national-id" },
    { label: "Voter's card", value: "voters-card" },
  ];
  formatterNaira = (value: number) => `NGN ${value}`;
  parserNaira = (value: string) => value.replace("NGN ", "");

  planCategory$: BehaviorSubject<string>;
  subProductId$: BehaviorSubject<string>;
  selectedPlan$: BehaviorSubject<any>;
  loading!: boolean | undefined;
  errorStatus$ = new BehaviorSubject('');
  errorMessage$ = new BehaviorSubject('');
  error!: boolean | undefined;
  extraInfo$: BehaviorSubject<any>;
  titles = [];
  genders = [];
  maritals=[];
  states=[];
  insurance: InsuranceVm;
  transactionData$ = new BehaviorSubject(new TransactionModel());

  transactionStatus$: BehaviorSubject<string>;
  transactionDescription$: BehaviorSubject<string>;
  interswitchOptions: InterswitchOptionsModel;
  selectedState:string;



  formErrors$ = new BehaviorSubject([]);
  cat: any;
  countries: any;
  clientCats: any;
  travelPlanObj: { subClassCoverTypes: { productName: string; subClassName: string; }; };
  errorArray: any[];
  caption: string;
  yearsOfDriving: any;

  fileData: File = null;

  attachedFilesNames = {
    id: '',
    license: '',
    bill: '',
    passport: ''
  };
  beneficiaryValid: boolean;
  currentPosition: number;

  showFlutterwavePayment: boolean = false;
  // options$: BehaviorSubject<{ label: string; value: string; }[]>;
  paymentOption = '';
  raveOptions: RaveOptionsModel;
  transactionRef: string;

  isAgentCodeValid: boolean = false;
  agentCodeValidationResponse='';
  country='';

  constructor(
    private _hospitalCashService: HospitalCashService,
    public renewalService: RenewalService,
    public utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone, @Inject(DOCUMENT)
    private document: Document,
    private renderer: Renderer2,
    public productService: ProductsService,
    public utilityService: UtilityService,
    public motorService: MotorProductService,
    public paymentService: PaymentService,
    private _termlifeService:TermLifeService
    ) {

    this.current$ = new BehaviorSubject(0);
    this.selectedValue$ = new BehaviorSubject("");
    this.options$ = new BehaviorSubject(this.validIds);

    this.planCategory$ = new BehaviorSubject("");
    this.subProductId$ = new BehaviorSubject("");

    this.selectedPlan$ = new BehaviorSubject({});
    this.extraInfo$ = new BehaviorSubject(new Object());

    this.insurance = new InsuranceVm();
    this.transactionStatus$ = new BehaviorSubject('');
    this.transactionDescription$ = new BehaviorSubject('');
    this.interswitchOptions = new InterswitchOptionsModel();
  }


  setDefaultDate() {
    this.productScheduleRequest.dob = new Date();
    this.productScheduleRequest.agentShortDescription='C6BD86';
    this.productScheduleRequest.dob.setFullYear(this.productScheduleRequest.dob.getFullYear() - 18);
  }

  // formatterNaira = (value: number) => `₦ ${value}`;
  // parserNaira  = (value: string) => value.replace('₦ ', '');

  ngOnInit() {
    this.personalKYC = true;
    this.productDetails = false;
    this.productUpload = false;
    this.productReview = false;
    this.productPayment = false;
    this.successfulPayment = false;
    this.sectionOne = true;
    this.sectionTwo = false;
    this.sectionThree = false;

    this.confirmDetails = false;
    this.currentPosition = 0;
    this.beneficiaryValid = false;

    this.loading = false;
    this.error = true;



    this.productScheduleRequest = new HospitalCashScheduleRequest();
    this.productScheduleResponse = new HospitalCashScheduleRequest();
    this.selectedProductDetails = new ProductSubClassCoverTypesModelFinal();
    this.selectedProductDetails.subClassCoverTypes = new ProductSubClassCoverTypesModel();

    const info = EXTRA_INFO.filter(info => info.id === '9f304355-43c0-ea11-a336-005056a00da2')[0];
    this.extraInfo$.next(info);
    this.getProductSubclassWithProductId(info.id);

    this.getTitles();
    this.getGenders();
    this.getMaritals();
    this.getStates();
  }

  ngAfterViewInit(): void {
    this.setDefaultDate();
    this.interswitchOptions = new InterswitchOptionsModel();
    this.raveOptions = new RaveOptionsModel();

  }

 
  getStates() {

    this.loading = true;
    this.error = false;
    

    this.utilService.GetCountries().subscribe(c => {
      if(c) {
        this.localGovtAreas.length = 0;
        let nigeria= c.result.filter(x=>x.name ==='Nigeria');
        this.country= nigeria[0].id;
        this.utilService.getStates(this.country).subscribe(data => {
          if (data) {
            this.states = [...data.result];
            localStorage.setItem('states', JSON.stringify(this.states));
          }
          this.loading = false;
    
        }), error => {
          this.errorStatus$.next(`${error.status}`);
          this.errorMessage$.next(error.statusText);
          this.error = true;
          this.loading = false;
        }}}), error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;
    };
  }


  getLga(stateId) {
    this.loading = true;
    this.error = false;
    this.hospitals.length = 0;

    this.utilService.getLGAs(stateId).subscribe(data => {
      if (data) {
        this.localGovtAreas = [...data.result];
        localStorage.setItem('filteredLgas', JSON.stringify(this.localGovtAreas));
      }
      this.loading = false;

    }), error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;
    };
  }

  selectLga(event) {
    this.getLga(event);
  }

  selectHospital(event) {
    this.getHospital(event);
  }


  getHospital(lgaId) {
    this.loading = true;
    this.error = false;

    this.utilService.getHospitals(lgaId).subscribe(data => {
      if (data) {
        this.hospitals = [...data.result];
        localStorage.setItem('hospitals', JSON.stringify(this.hospitals));
      }
      this.loading = false;

    }), error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;
    };
  }


  validateAgentDescription(){
    if(this.productScheduleRequest.agentShortDescription.length < 4){
      this.agentCodeValidationResponse='';
      return;
    }
    var code=this.productScheduleRequest.agentShortDescription;


    this.loading=true;
    this.error=false;
    this.renewalService.GetAgentDetails(code).subscribe(res => {
      if (res) {
        if(res.result.isSuccessful===true){
          this.isAgentCodeValid=true;
          this.agentCodeValidationResponse='Agent Code Successfully Validated';
        }else{
          this.isAgentCodeValid=false;
          this.productScheduleRequest.agentShortDescription='';
          this.agentCodeValidationResponse='Fail: Invalid identity';

        }
      }
      this.loading=false;
    }), error => {
     this.loading=false;
     this.isAgentCodeValid=false;
     this.productScheduleRequest.agentShortDescription='';
     this.agentCodeValidationResponse='Fail: Invalid identity';
    }
    setTimeout(() => {this.agentCodeValidationResponse='';}, 5000);
  }

  calculatePremiunm(event) {
    this.productScheduleRequest.paymentFrequency=event;

    var amountToPay = 0;

    if (this.productScheduleRequest.paymentFrequency === "Annually") {
        amountToPay = 16000;
    }
    else if (this.productScheduleRequest.paymentFrequency === "Semi-Annually") {
        amountToPay = 8700;
    }
    else if (this.productScheduleRequest.paymentFrequency === "Quarterly") {
        amountToPay = 4500;
    }
    else if (this.productScheduleRequest.paymentFrequency === "Monthly") {
        amountToPay = 1600;
    }

    this.productScheduleRequest.premium = amountToPay.toString();
   // this.productScheduleRequest.sumAssured = this.sumAssured.toString();
  }

  getProductSubclassWithProductId(productId: string): void {

    this.loading = true;
    this.error = false;
    this.productService.getProductSubClass(productId).subscribe(
      res => {
        if (res) {
            this.getSingleSubclass(res.result[0].subClassCoverTypes.id);
        }
        this.loading = false;
      }, error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      });
            this.loading = true;
            this.error = false;

  }

  getSingleSubclass(subclassId: string): void {
    this.productService.getSingleProductSubClass(subclassId).subscribe(
      (data) => {
        if (data) {
          this.selectedPlan$.next(data.result);
          this.selectedProductDetails = data.result;
          // this.selectedProductDetails.subClassCoverTypes = data.subClassCoverTypes;
          this.caption = 'Wema Bank Woman Health Plan';// this.selectedProductDetails.subClassCoverTypes.productName;
          // localStorage.setItem('selectedPlan', JSON.stringify(data.result));
        }
        this.loading = false;
      },
      error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.loading = false;
      });
  }

  setPaymentData() {
    const paymentData = this.productScheduleResponse;
    this.transactionRef = this.productScheduleResponse.transactionRef;

    // rave
    this.raveOptions.PBFPubKey = this.productScheduleResponse.ravePublicKey;
    this.raveOptions.txref = this.productScheduleResponse.transactionRef;
    this.raveOptions.customer_email = this.productScheduleResponse.email;
    this.raveOptions.customer_firstname = this.productScheduleResponse.surName;
    this.raveOptions.customer_lastname = this.productScheduleResponse.otherNames;
    this.raveOptions.amount = Number(this.productScheduleResponse.premium);
    this.raveOptions.customer_phone = this.productScheduleResponse.phoneNumber;

    // interswitch
    this.interswitchOptions.macKey = this.productScheduleResponse.interswitchMacKey;
    this.interswitchOptions.amount = Number(this.productScheduleResponse.premium)  * 100;
    this.interswitchOptions.txn_ref = this.productScheduleResponse.transactionRef;
    this.interswitchOptions.macKey = this.productScheduleResponse.interswitchMacKey;
    this.interswitchOptions.pay_item_id = this.productScheduleResponse.interswitchProductItemId;
    this.interswitchOptions.product_id = this.productScheduleResponse.interswitchProductId;
    this.interswitchOptions.name = `${this.productScheduleResponse.surName}  ${this.productScheduleResponse.otherNames}`;
    const token = this.interswitchOptions.txn_ref + this.interswitchOptions.product_id + this.interswitchOptions.pay_item_id + this.interswitchOptions.amount + this.interswitchOptions.redirect_url + this.interswitchOptions.macKey;
    this.interswitchOptions.hash = sha512(token).toUpperCase();

    this.raveOptions.custom_description = this.caption;


    this.interswitchOptions.pay_item_name = this.caption;
  }

  validatePersonalKYCForm(){
    this.showProductDetailsForm();
    this.currentPosition = 1;
  }

  showPreviousPersonalKYCForm(){
    this.currentPosition = 0;
    this.showPersonalKYCForm();
  }

  showPersonalKYCForm(){
    this.sectionOne = true;
    this.sectionTwo = false;
    this.sectionThree = false;

    this.personalKYC = true;
    this.productDetails = false;
    this.productUpload = false;
    this.productReview = false;
    this.productPayment = false;
    this.successfulPayment = false;
  }

  validateProductDetailsForm(){
    this.showProductUploadForm();
    this.currentPosition = 2;
  }

  showPreviousProductDetailsForm(){
    this.currentPosition = 1;
    this.showProductDetailsForm();
  }

  showProductDetailsForm(){
    this.sectionOne = true;
    this.sectionTwo = false;
    this.sectionThree = false;

    this.personalKYC = false;
    this.productDetails = true;
    this.productUpload = false;
    this.productReview = false;
    this.productPayment = false;
    this.successfulPayment = false;
  }

  validateProductUploadForm(){
    if(this.beneficiaryValid || this.productScheduleRequest.identificationName === ''){

      if(this.beneficiaryValid){

        /// Validate the form fields for Beneficiaries
      }

      if(this.productScheduleRequest.identificationName === ''){
        this.isInputValid('Kindly upload your id');
        return false;
      }

    } else {
      this.showProductReviewForm();
      this.currentPosition = 3;
    }
  }

  showPreviousProductUploadForm(){
    this.currentPosition = 2;
    this.showProductUploadForm()
  }

  showProductUploadForm(){
    this.sectionOne = true;
    this.sectionTwo = false;
    this.sectionThree = false;

    this.personalKYC = false;
    this.productDetails = false;
    this.productUpload = true;
    this.productReview = false;
    this.productPayment = false;
    this.successfulPayment = false;
  }

  validateProductReviewForm(){
    this.postProductSchedule();
  }

  showPreviousProductReviewForm(){
    this.currentPosition = 3;
    this.showProductReviewForm();
  }

  showProductReviewForm(){
    this.sectionOne = false;
    this.sectionTwo = true;
    this.sectionThree = false;

    this.personalKYC = false;
    this.productDetails = false;
    this.productUpload = false;
    this.productReview = true;
    this.productPayment = false;
    this.successfulPayment = false;
  }

  validateProductPaymentForm(){
    this.showProductDetailsForm();
    this.currentPosition += 1;
  }

  showPreviousProductPaymentForm(){
    this.currentPosition = 4;
    this.showProductPaymentForm()
  }

  showProductPaymentForm(): void {
    this.sectionOne = false;
    this.sectionTwo = true;
    this.sectionThree = false;

    this.personalKYC = false;
    this.productDetails = false;
    this.productUpload = false;
    this.productReview = false;
    this.productPayment = true;
    this.successfulPayment = false;
  }

  validateSuccessfulPaymentForm(){
    this.showProductPaymentForm();
    this.currentPosition += 1;
  }

  showSuccessfulPaymentForm(){
    this.sectionOne = false;
    this.sectionTwo = false;
    this.sectionThree = true;

    this.personalKYC = false;
    this.productDetails = false;
    this.productUpload = false;
    this.productReview = false;
    this.productPayment = false;
    this.successfulPayment = true;
  }

  formatAmountToString(val) {
    try {

      if (val) {
        val = this.formatAmountToNumber(val);
        return (parseInt(val.toString().replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US');
      }
    }
    catch (e) { }
  }

  formatAmountToNumber(val) {
    if (val)
      return val = typeof val == 'number' ? val : parseFloat(val.replace(/,/g, ''));
  }

  getTitles() {
    this.loading = true;
    this.error = false;

    this.utilService.GetTitles().subscribe(data => {
      if (data) {

        let filterTitles=data.result.filter(x=>x.name==='Mrs' || x.name==='Miss');
        this.titles = [...filterTitles];
        localStorage.setItem('titles', JSON.stringify(this.titles));
      }
      this.loading = false;

    }), error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;
    };
  }

  getGenders() {
    this.loading = true;
    this.error = false;

    this.utilService.GetGenders().subscribe(data => {
      if (data) {
        let filterGender=data.result.filter(x=>x.name==='Female');

        this.genders = [...filterGender];
        localStorage.setItem('genders', JSON.stringify(this.genders));
      }
      this.loading = false;

    }, error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;
    });
  }
  getMaritals() {
    this.loading = true;
    this.error = false;

    this.utilService.GetMaritals().subscribe(data => {
      if (data) {

        this.maritals = [...data.result];
        localStorage.setItem('maritals', JSON.stringify(this.maritals));
      }
      this.loading = false;

    }, error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;
    });
  }


  postProductSchedule() {
    this.productScheduleRequest.agentShortDescription='40699635';
    this.productScheduleRequest.referralCode='C6BD86';
    this.productScheduleRequest.wef = this.productScheduleRequest.effectiveDate;
    this.productScheduleRequest.wet = this.productScheduleRequest.effectiveDate;
    this.productScheduleRequest.productId = this.selectedProductDetails.subClassCoverTypes.productId;
    this.productScheduleRequest.subclassSectCovtypeId = this.selectedProductDetails.subClassCoverTypes.id;
    this.productScheduleRequest.premium = parseInt(this.productScheduleRequest.premium.toString()).toString();
    //this.productScheduleRequest.unit = parseInt(this.productScheduleRequest.premium.toString());
    this.loading = true;
    this.error = false;

    this._hospitalCashService.postHospitalCashSchedule(this.productScheduleRequest)
    .subscribe(res => {


      if (res) {
        this.productScheduleResponse = res.result as HospitalCashScheduleRequest;

        this._termlifeService.postWomenPlanTermLifeCover(this.productScheduleResponse.clientId,this.productScheduleResponse.transactionRef,false,'','','','').subscribe(),error => {
          this.errorStatus$.next(`${error.status}`);
          this.errorMessage$.next(error.statusText);
          this.error = true;
          this.loading = false;
        };
  
        this.error = false;
        this.loading = false;

        this.currentPosition = 4;
        this.showProductPaymentForm();
        this.setPaymentData();

        // this.sectionOne = false;
        // this.sectionTwo = true;
        // this.sectionThree = false;

        // this.personalKYC = false;
        // this.productDetails = false;
        // this.productUpload = false;
        // this.productReview = false;
        // this.productPayment = true;
        // this.successfulPayment = false;
      }
    },
    error => {
      this.errorStatus$.next(`${error.status}`);
      this.errorMessage$.next(error.statusText);
      this.error = true;
      this.loading = false;
    });

  }

  isInputValid(name: string) {
    if (this.formErrors$.value.length) {
      return this.formErrors$.value[0].toLowerCase().includes(name);
    }
    return false;
  };

  handleChange(file: any, selectedValue: string): void {

    this.fileData = <File>file.target.files[0];

    var reader: FileReader = new FileReader();

    reader.readAsDataURL(this.fileData);
    reader.onloadend = (e) => {

      switch (selectedValue) {
        case "id":
          this.productScheduleRequest.identificationUrl = reader.result as string;
          this.productScheduleRequest.identificationName = this.fileData.name;
          break;

        case "license":
          this.insurance.VehicleLicenseUrl = reader.result as string;
          this.attachedFilesNames.license = this.fileData.name;
          break;

        case "bill":
          this.insurance.UtilityBillUrl = reader.result as string;
          this.attachedFilesNames.bill = this.fileData.name;
          break;

        default:
          this.insurance.PassportUrl = reader.result as string;
          this.attachedFilesNames.passport = this.fileData.name;
          break;
      }

    }
  }

  onChange(result: Date): void {
    // console.log("onChange: ", result);
  }

  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) < 0;
  };

  disabledDOB = (current: Date): boolean => {
    var date = new Date();
    var eighteenYearsAgo = date.getFullYear() - 18;
    date.setFullYear(eighteenYearsAgo)
    return differenceInCalendarDays(current, date) > 0;
  };




  disabledEffectiveDate = (current: Date): boolean => {
    var date = new Date();
    var aDayAgo = date.getHours() - 24;
    date.setHours(aDayAgo)
    return differenceInHours(date, current) > 0;
  };

  confirmPayment(response: any): void {

    const { tx } = response;

    this.saveTransactionRef(tx);

    this.cancelledPayment();

    this.ngZone.run(() => {
      const elem = this.document.querySelector('[name=checkout]');
      this.renderer.removeChild(this.document.body, elem);
      this.renderer.addClass(this.document.body, 'overflow-auto');
      // this.router.navigateByUrl("result/flutterwave");
    });

  }

  saveTransactionRef(tx) {
    const transactionDetails = {
      product: this.raveOptions.custom_description,
      txnref: this.raveOptions.txref,
      ispolicyrenewal: true,
      chargeResponseCode: tx.chargeResponseCode,
      chargeResponseMessage: tx.chargeResponseMessage
    }

    if (transactionDetails) {
      if (this.paymentOption !== 'flutterwave') {

        this.loading = true;

        this.paymentService.getTransactionDetails(this.productScheduleResponse.transactionRef, this.productScheduleResponse.premium)
        .subscribe(res => {
          this.loading = false;

          this.finalizeInterswitchPayment(this.productScheduleResponse.transactionRef, this.productScheduleResponse.premium, this.caption, false, res.success);

          if (res.success) {
            this.transactionStatus$.next("Payment Successful");
            this.transactionDescription$.next(`${this.caption} was paid for successfully`);
          } else {
            this.transactionStatus$.next("Payment Failed");
            this.transactionDescription$.next(res.error);
          }

          this.showSuccessfulPaymentForm();

        }, err => {
          this.transactionStatus$.next("Transaction Failed");
          this.transactionDescription$.next(err.error.message);
          this.loading = false;
        });

      } else {

        this.finalizeRavePayment(this.productScheduleResponse.transactionRef, this.caption, false, tx.chargeResponseCode)

        if (tx.chargeResponseCode == '00' || tx.chargeResponseCode == '0') {
          this.transactionStatus$.next("Payment Successful");
          this.transactionDescription$.next(`${this.caption} was paid for successfully`);
        } else {
          this.transactionStatus$.next("Payment Failed");
          this.transactionDescription$.next(tx.chargeResponseMessage);
        }

        this.showSuccessfulPaymentForm();
      }
    } else {
      this.transactionStatus$.next("Transaction Failed");
      this.transactionDescription$.next("transaction details was not provided");
      this.loading = false;
    }

  }

  cancelledPayment(): void {
    console.log("transaction completed");
  }


  //FINALIZED METHOD
  finalizeInterswitchPayment(transactionRef, amount, product, ispolicyrenewal, isSuccessful): any {
    this.loading = true;
    this.error = false;

    var ref = {
      transactionRef,
      amount,
      interswitchMacKey: this.interswitchOptions.macKey
    }

    let transactionData = new TransactionModel();
    transactionData.txnref = transactionRef;
    transactionData.productType = product;

    if (isSuccessful) {
      transactionData.gatewayResponse = "Approved Successful";
    } else {
      transactionData.gatewayResponse = "Transaction Failed";
    }

    if (ispolicyrenewal) {
      this.productService.FinalizeInterswitchPolicyPayment(ref).subscribe(res => {
        this.loading = false;
        transactionData = { ...this.extractInterswitchData(transactionData, res.result) };
        console.log(transactionData);
        this.transactionData$.next(transactionData);

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      }
    } else {
      this.productService.FinalizeInterswitchPayment(ref).subscribe(res => {
        this.loading = false;
        console.log(res);
        transactionData = { ...this.extractInterswitchData(transactionData, res.result) };
        console.log(transactionData);
        this.transactionData$.next(transactionData);
      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      }
    }

  }

  extractInterswitchData(transactionData, apiResponse) {
    const { item1, item2 } = apiResponse;

    transactionData.policies = item2.policies;
    transactionData.printPolicyUrl = item2.printPolicyUrl;
    transactionData.totalAmount = item2.totalAmount;
    transactionData.wef = item2.wef;
    transactionData.wet = item2.wet;
    transactionData.cardNumber = item1.cardNumber;
    transactionData.paymentReference = item1.paymentReference;

    return transactionData;
  }

  finalizeRavePayment(transactionRef, product, ispolicyrenewal, chargeResponseCode): any {
    this.loading = true;
    this.error = false;

    let transactionData = new TransactionModel();
    transactionData.txnref = transactionRef;
    transactionData.productType = product;

    if (chargeResponseCode == "00" || chargeResponseCode == "0") {
      transactionData.gatewayResponse = "Approved Successful";
    } else {
      transactionData.gatewayResponse = "Transaction Failed";
    }

    if (ispolicyrenewal) {
      this.productService.FinalizeFlutterwavePolicyPayment(transactionRef).subscribe(res => {
        this.loading = false;
        console.log(res);

        transactionData = { ...this.extractRaveData(transactionData, res.result) };
        this.transactionData$.next(transactionData);
        this.loading = false;

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      }
    } else {
      this.productService.FinalizeFlutterwavePayment(transactionRef).subscribe(res => {
        this.loading = false;
        console.log(res);

        transactionData = { ...this.extractRaveData(transactionData, res.result) };
        this.transactionData$.next(transactionData);
        this.loading = false;

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      }
    }
  }

  extractRaveData(transactionData, apiResponse) {
    const { item1, item2 } = apiResponse;

    transactionData.policies = item2.policies;
    transactionData.printPolicyUrl = item2.printPolicyUrl;
    transactionData.totalAmount = item2.totalAmount;
    transactionData.wef = item2.wef;
    transactionData.wet = item2.wet;
    transactionData.cardNumber = item1.data.card.cardBIN;
    transactionData.paymentReference = item1.data.flw_ref;

    return transactionData;
  }

  joinArr(arr) {
    if (arr) {
      return arr.join(', ');
    }

    return 'N/A';
  }

  formatCurrency(value) {
    const num = parseFloat(value);

    if (num === num) {
      return `\u20A6${value}`;
    }

    return value;
  }

}
