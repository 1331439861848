import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from "rxjs/operators";
import { BaseService } from './base.service';
// import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PaymentService {
  constructor(public baseService: BaseService, private _http: HttpClient) {
    this.baseService.http = _http;
  }

  getTransactionDetails(txnref, amount) {
    // ${this.baseService.baseUrl}
    const url = `${this.baseService.baseUrl}/InterswitchPaymentService/GetPaymentValidationResult?transactionRef=${txnref}&amount=${amount}`;

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }
}
