import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public confirmDetails = false;

  constructor(public baseService: BaseService, private _http: HttpClient) {
    this.baseService.http = _http;
  }

  GetTitles(): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetTitles`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetGenders(): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetGenders`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetMaritals(): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetMaritalStatus`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

 
 

  getStates(countryId): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetStatesForCountry?countryId=${countryId}`
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }


  getLGAs(stateId): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetLgasForState?stateId=${stateId}`
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  getLGs(): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetAllLgas`
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }


  getHospitals(lgaId): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetHospitalsForLga?lgaId=${lgaId}`
    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }


  GetBodyTypes(): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetBodyTypes`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  getAllBanks() : Observable<any>{
    const url = `${this.baseService.baseUrl}/VerificationService/GetAllBanks`;

    return this.baseService.http
    .get<any>(url, { headers : this.baseService._headers})
    .pipe(
      catchError(this.baseService._serverError)
    );
  }

  verifyBankDetails(bankDetails) : Observable<any>{
    const url = `${this.baseService.baseUrl}/VerificationService/VerifyNuban`;

    return this.baseService.http
    .post<any>(url, bankDetails, {headers : this.baseService._headers})
    .pipe(
      catchError(this.baseService._serverError)
    );
  }

  GetManufactureYear(): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetManufactureYear`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetColorList(): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetColorList`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetVehicleMakeModel(make, year): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetVehicleMakeModel?VehicleMake=${make}&VehicleYear=${year}`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetVehicleMake(year): Observable<any> {

    const url = `${this.baseService.baseUrl}/UtilitiyService/GetVehicleMake?makeYear=${year}`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  ComputeThirdPartyPremium(bodyType): Observable<any> {

    const url = `${this.baseService.baseUrl}/MotorProductService/ComputeThirdPartyMotorPremium?bodyType=${bodyType}`

    return this.baseService.http
      .post<any>(url, null, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetCountries(): Observable<any> {
    const url = `${this.baseService.baseUrl}/UtilitiyService/GetCountries/`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetSchengenCountries(): Observable<any> {
    const url = `${this.baseService.baseUrl}/UtilitiyService/GetSchengenCountries/`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetSchengenCountriesPlus(): Observable<any> {
    const url = `${this.baseService.baseUrl}/UtilitiyService/GetSchengenCountriesPlus/`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetAfricanCountries(): Observable<any> {
    const url = `${this.baseService.baseUrl}/UtilitiyService/GetAfricanCountries/`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetPilgrimageCountries(): Observable<any> {
    const url = `${this.baseService.baseUrl}/UtilitiyService/GetPilgrimageCountries/`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetClientCategories(): Observable<any> {
    const url = `${this.baseService.baseUrl}/UtilitiyService/GetClientCategories/`

    return this.baseService.http
      .get<any>(url, { headers: this.baseService.headers })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  GetWeatherForCity(lat, long, date): Observable<any> {
    var proxy = 'https://cors-anywhere.herokuapp.com/';
    const url = `https://api.darksky.net/forecast/6bd5c02ef01d94649e2fbcd9d2a35ad6/${lat},${long},${date}?exclude=currently,flags,hourly?units=auto`;
    return this.baseService.http
      .get<any>(proxy + url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Authorization',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Credentials': 'true'
        })
      })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }

  getCountryCapital(country): Observable<any> {
    const url = `https://restcountries.eu/rest/v2/name/${country}`;

    return this.baseService.http
      .get<any>(url, {
        headers: new HttpHeaders({

        })
      })
      .pipe(
        catchError(this.baseService._serverError)
      );
  }
}
