import { AfterViewInit, Component, EventEmitter, Inject, NgZone, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InsuranceVm } from "@app/models/insurance";
import { EXTRA_INFO } from "@app/shared/data";
import { differenceInCalendarDays } from "date-fns";
import { BehaviorSubject } from "rxjs";
import {TermLifeService} from "src/app/services/termlife-service";


@Component({
  selector: 'app-termlife-beneficiary',
  templateUrl: './termlife-beneficiary.component.html',
  styleUrls: ['./termlife-beneficiary.component.scss']
})
export class TermlifeBeneficiaryComponent implements OnInit{

  loading!: boolean | undefined;
  errorStatus$ = new BehaviorSubject('');
  errorMessage$ = new BehaviorSubject('');
  error!: boolean | undefined;
  extraInfo$: BehaviorSubject<any>;
  transactionRef: string;
  clientId:string;
  outputMessage$ = new BehaviorSubject('');
  outputMessageDescription$ = new BehaviorSubject('');
  date = null;
  today = new Date();
  beneficiaryDob?: Date | undefined;
  beneficiaryPhoneNumber?: string | undefined;
  beneficiaryFullName?: string | undefined;
  formErrors$ = new BehaviorSubject([]);
  message:string;
 

  constructor(
    private route: ActivatedRoute,
    private _termAssurance:TermLifeService

  ) {  }

  ngOnInit() {

    this.beneficiaryDob = new Date();
    this.transactionRef = this.route.snapshot.queryParamMap.get('transRef');
    this.loading = true;
    this.error = false;
    console.log(this.transactionRef);
    if(this.transactionRef === null||this.transactionRef ===''||!this.transactionRef.includes('-')) {
      this.loading=false;
      this.error=true;
      this.outputMessage$.next('Invalid Transaction Reference');
      this.outputMessageDescription$.next('Failed');
      return;
    } 
    this._termAssurance.GetTransactionBeneficiary(this.transactionRef).subscribe(res => {
      this.loading=false;
      if (res) {
        this.clientId=res.result.clientId;
        this.beneficiaryFullName=res.result.beneficiaryFullName;
        this.beneficiaryPhoneNumber=res.result.beneficiaryPhoneNumber;
        this.beneficiaryDob=res.result.beneficiaryDateOfBirth;
        
      }
    }, err => {
      console.log(err);
      this.loading=false;
      this.error=true;
      this.outputMessage$.next(err.statusText);
      this.outputMessageDescription$.next(err.error.error.message);
    }); 
  }


  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) < 0;
  };

  disabledDOB = (current: Date): boolean => {
    var date = new Date();
    var eighteenYearsAgo = date.getFullYear() - 18;
    date.setFullYear(eighteenYearsAgo)
    return differenceInCalendarDays(current, date) > 0;
  };



  submitBeneficiaryForm(){
    //submit the form
    this.loading = true;
    this.error = false;
    this._termAssurance.postTermLifeCover(this.clientId,this.transactionRef,true,this.beneficiaryFullName,this.beneficiaryPhoneNumber,this.beneficiaryDob).subscribe(res => {
      if (res) {
        //triger a new certificate
        this.message='Your beneficiary details has been successfully updated';
        this._termAssurance.PostPersonalAccidentCertificate(this.transactionRef)
        .subscribe(result=>{
        this.error = false;
        this.loading = false;

        },
        error => {
          this.outputMessage$.next(`${error.status}`);
          this.outputMessageDescription$.next(error.statusText);
          this.error = true;
          this.loading = false;
        });
    
      }
      this.error = false;
      this.loading = false;
    },
    error => {
      this.outputMessage$.next(`${error.status}`);
      this.outputMessageDescription$.next(error.statusText);
      this.error = true;
      this.loading = false;
    });

  }




}
