import { Component, EventEmitter, Inject, NgZone, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ProductsService } from 'src/app/services/products.service';
import { UtilService } from 'src/app/services/util.service';
import { UtilityService } from 'src/app/services/utility.service';
import { RenewalService } from 'src/app/services/renewal.service';
import { InsuranceVm } from 'src/app/models/insurance';
import { MotorProductService } from 'src/app/services/motor-product.service';
import {  MotorScheduleRequest,ProductSubClassCoverTypesModelFinal } from '@shared/service-proxies/service-proxies';
import { InterswitchOptionsModel } from "@app/models/interswitchOptions";
import { RaveOptionsModel } from "@app/models/raveOptions";
import { sha512 } from "js-sha512";
import { DOCUMENT } from "@angular/common";
import differenceInHours from "date-fns/differenceInHours";
import { HospitalCashService } from "@app/services/hospital-cash-service";
import { TransactionModel } from "@app/models/transaction";
import { PaymentService } from "@app/services/payment.service";
import { PaystackOptionsModel } from "@app/models/paystackOptions";

@Component({
  selector: 'app-motor-renewal',
  templateUrl: './motor-renewal.component.html',
  styleUrls: ['./motor-renewal.component.scss']
})
export class MotorRenewalComponent implements OnInit {

  policyNumber:string;
 
  productPayment!: boolean | undefined;
  successfulPayment!: boolean | undefined;
  
  confirmDetails!: boolean | undefined;
  productDetailsDisabled: BehaviorSubject<boolean>;
  dateOfBirth!: Date | undefined;
  sumAssured?: number | undefined;
  localGovtAreas = [];



  productScheduleRequest!: MotorScheduleRequest | undefined;
  productScheduleResponse!: MotorScheduleRequest | undefined;
  selectedProductDetails!: ProductSubClassCoverTypesModelFinal | undefined;
  productDescription:string;

  today = new Date();
  current$: BehaviorSubject<number>;
  date = null; // new Date();
  selectedValue$: BehaviorSubject<string>;
  isVisible = false;

  selectedOption: any;
  options$: BehaviorSubject<Array<object>>;
 
  formatterNaira = (value: number) => `NGN ${value}`;
  parserNaira = (value: string) => value.replace("NGN ", "");

  planCategory$: BehaviorSubject<string>;
  subProductId$: BehaviorSubject<string>;
  selectedPlan$: BehaviorSubject<any>;
  loading!: boolean | undefined;
  errorStatus$ = new BehaviorSubject('');
  errorMessage$ = new BehaviorSubject('');
  error!: boolean | undefined;
  extraInfo$: BehaviorSubject<any>;
  titles = [];
  genders = [];
  insurance: InsuranceVm;
  transactionData$ = new BehaviorSubject(new TransactionModel());

  transactionStatus$: BehaviorSubject<string>;
  transactionDescription$: BehaviorSubject<string>;
  interswitchOptions: InterswitchOptionsModel;



  formErrors$ = new BehaviorSubject([]);
  cat: any;
  countries: any;
  clientCats: any;
  travelPlanObj: { subClassCoverTypes: { productName: string; subClassName: string; }; };
  errorArray: any[];
  caption: string;
  yearsOfDriving: any;

 
  beneficiaryValid: boolean;
  currentPosition: number;

  showFlutterwavePayment: boolean = false;

  paymentOption = '';
  raveOptions: RaveOptionsModel;
  paystackOptions:PaystackOptionsModel;
  transactionRef: string;

  isAgentCodeValid: boolean = false;
  agentCodeValidationResponse='';

  constructor(
    public renewalService: RenewalService,
    public utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone, @Inject(DOCUMENT)
    private document: Document,
    private renderer: Renderer2,
    public productService: ProductsService,
    public utilityService: UtilityService,
    public motorService: MotorProductService,
    public paymentService: PaymentService,
    ) {
   
      

    this.current$ = new BehaviorSubject(0);
    this.selectedValue$ = new BehaviorSubject("");
    this.productDetailsDisabled = new BehaviorSubject(true);
 

    this.planCategory$ = new BehaviorSubject("");
    this.subProductId$ = new BehaviorSubject("");

    this.selectedPlan$ = new BehaviorSubject({});
    this.extraInfo$ = new BehaviorSubject(new Object());

    this.insurance = new InsuranceVm();
    this.transactionStatus$ = new BehaviorSubject('');
    this.transactionDescription$ = new BehaviorSubject('');
    this.interswitchOptions = new InterswitchOptionsModel();
    this.raveOptions = new RaveOptionsModel();
    this.paystackOptions=new PaystackOptionsModel();
  }


  ngOnInit() {
    
    this.productPayment = false;
    this.successfulPayment = false;

    this.confirmDetails = false;
    this.currentPosition = 0;

    this.loading = true;
    this.error = false;



    this.productScheduleRequest = new MotorScheduleRequest();
    this.productScheduleResponse = new MotorScheduleRequest();


    this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');
    console.log(this.policyNumber);
    if(this.policyNumber !==''||this.policyNumber.length > 0) {

      this.retreiveMotorSchedule(this.policyNumber);

    }else{
      this.loading=false;
      this.error=true;
      this.errorStatus$.next("Failed");
      this.errorMessage$.next('Invalid Policy Number');
    }

  }

 /*  ngAfterViewInit(): void {
   
    this.interswitchOptions = new InterswitchOptionsModel();
    this.raveOptions = new RaveOptionsModel();

  } */



  getProductSubclassWithProductId(productId: string): void {

    this.loading = true;
    this.error = false;
    this.productService.getProductSubClass(productId).subscribe(
      res => {
        if (res) {
            this.getSingleSubclass(res.result[0].subClassCoverTypes.id);
        }
        this.loading = false;
      }, error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      });
            this.loading = true;
            this.error = false;

  }

  getSingleSubclass(subclassId: string): void {
    this.productService.getSingleProductSubClass(subclassId).subscribe(
      (data) => {
        if (data) {
          this.selectedPlan$.next(data.result);
          this.selectedProductDetails = data.result;
          // this.selectedProductDetails.subClassCoverTypes = data.subClassCoverTypes;
          this.caption = this.selectedProductDetails.subClassCoverTypes.productName;
          // localStorage.setItem('selectedPlan', JSON.stringify(data.result));
        }
        this.loading = false;
      },
      error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.loading = false;
      });
  }

  setPaymentData() {
    const paymentData = this.productScheduleResponse;
    this.transactionRef = this.productScheduleResponse.transactionRef;

    // rave
    this.raveOptions.PBFPubKey = this.productScheduleResponse.ravePublicKey;
    this.raveOptions.txref = this.productScheduleResponse.transactionRef;
    this.raveOptions.customer_email = this.productScheduleRequest.pryEmail ? this.productScheduleRequest.pryEmail : 'aiicontact@aiicoplc.com';
    this.raveOptions.customer_firstname = this.productScheduleResponse.firstName;
    this.raveOptions.customer_lastname = this.productScheduleResponse.lastName;
    this.raveOptions.amount = Number(this.productScheduleResponse.premiumAmount);
    this.raveOptions.customer_phone = this.productScheduleRequest.smsTel;


     //paystack
     this.paystackOptions.txref =  this.productScheduleResponse.transactionRef;
     this.paystackOptions.email =  this.productScheduleRequest.pryEmail ? this.productScheduleRequest.pryEmail : 'aiicontact@aiicoplc.com';
     this.paystackOptions.amount = Number(this.productScheduleResponse.premiumAmount)  * 100;
     this.paystackOptions.PBFPubKey = this.productScheduleResponse.paystackPublicKey;
     this.paystackOptions.subaccount=this.productScheduleResponse.paystackSubaccount;

    // interswitch

    this.interswitchOptions.macKey = this.productScheduleResponse.interswitchMacKey;
    this.interswitchOptions.amount = Number(this.productScheduleResponse.premiumAmount)  * 100;
    this.interswitchOptions.txn_ref = this.productScheduleResponse.transactionRef;
    this.interswitchOptions.cust_id = this.productScheduleResponse.transactionRef;
    this.interswitchOptions.macKey = this.productScheduleResponse.interswitchMacKey;
    this.interswitchOptions.pay_item_id = this.productScheduleResponse.interswitchProductItemId;
    this.interswitchOptions.product_id = this.productScheduleResponse.interswitchProductId;
    this.interswitchOptions.name = `${this.productScheduleResponse.lastName}  ${this.productScheduleResponse.firstName}`;
    const token = this.interswitchOptions.txn_ref + this.interswitchOptions.product_id + this.interswitchOptions.pay_item_id + this.interswitchOptions.amount + this.interswitchOptions.redirect_url + this.interswitchOptions.macKey;
    this.interswitchOptions.hash = sha512(token).toUpperCase();

    this.raveOptions.custom_description =this.productDescription;

    this.paystackOptions.meta =this.productDescription;
    this.interswitchOptions.pay_item_name = this.productDescription;


    
  }



 






 



  goto(step) {
    return this.current$.next(step);
  }








  formatAmountToString(val) {
    try {

      if (val) {
        val = this.formatAmountToNumber(val);
        return (parseInt(val.toString().replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US');
      }
    }
    catch (e) { }
  }

  formatAmountToNumber(val) {
    if (val)
      return val = typeof val == 'number' ? val : parseFloat(val.replace(/,/g, ''));
  }

 

  retreiveMotorSchedule(value){
    this.loading=true;
    this.error=false;
    //retrive 
    this.motorService.GetMotorRenewalSchedule(value).subscribe(result =>{
      if(result){
        console.log(result);
        this.productScheduleRequest=result.result.motorScheduleRequest;
        this.productDescription=result.result.productDescription;
        this.loading=false;
        this.error=false;
      }
    })

  }



  confirmFormFields() 
  {
    this.productScheduleRequest.oldPolicyNumber=this.policyNumber;
      this.loading=true;
      this.error=false;
      this.motorService.PostMotorRenewalSchedule(this.productScheduleRequest).subscribe(res => {
        if (res) {
          this.productScheduleResponse = res.result;
          this.error = false;
          this.loading = false;

          this.transactionRef = res.result.transactionRef;
          this.setPaymentData();
          this.current$.next(this.current$.value + 1);
        }
      }, error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error=true;
      }, () => this.loading=false)
    }

   

  isInputValid(name: string) {
    if (this.formErrors$.value.length) {
      return this.formErrors$.value[0].toLowerCase().includes(name);
    }
    return false;
  };





  confirmPayment(response: any): void {

    const { tx } = response;

    this.saveTransactionRef(tx);

    this.cancelledPayment();

    this.ngZone.run(() => {
      const elem = this.document.querySelector('[name=checkout]');
      this.renderer.removeChild(this.document.body, elem);
      this.renderer.addClass(this.document.body, 'overflow-auto');
      this.router.navigateByUrl("result/flutterwave");
    });

  }

  confirmPaystackPayment(response: any): void {
    const transactionDetails = {
      product: this.paystackOptions.meta,
      txnref: this.transactionRef,
      ispolicyrenewal: false,
      chargeResponseMessage: response.status
    }
    localStorage.setItem('transactionDetails', JSON.stringify(transactionDetails));
    this.cancelledPayment();

    this.ngZone.run(() => {
      this.router.navigateByUrl("result/paystack");
    });
  }

  saveTransactionRef(tx) {
    const transactionDetails = {
      product: this.raveOptions.custom_description,
      txnref: this.transactionRef,
      ispolicyrenewal: false,
      chargeResponseCode: tx.chargeResponseCode,
      chargeResponseMessage: tx.chargeResponseMessage
    }

    localStorage.setItem('transactionDetails', JSON.stringify(transactionDetails));
  }

  cancelledPayment(): void {
    console.log("transaction completed");
  }


  //FINALIZED METHOD
  finalizeInterswitchPayment(transactionRef, amount, product, ispolicyrenewal, isSuccessful): any {
    this.loading = true;
    this.error = false;

    var ref = {
      transactionRef,
      amount,
      interswitchMacKey: this.interswitchOptions.macKey
    }

    let transactionData = new TransactionModel();
    transactionData.txnref = transactionRef;
    transactionData.productType = product;

    if (isSuccessful) {
      transactionData.gatewayResponse = "Approved Successful";
    } else {
      transactionData.gatewayResponse = "Transaction Failed";
    }

    if (ispolicyrenewal) {
      this.productService.FinalizeInterswitchPolicyPayment(ref).subscribe(res => {
        this.loading = false;
        transactionData = { ...this.extractInterswitchData(transactionData, res.result) };
        console.log(transactionData);
        this.transactionData$.next(transactionData);

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      }
    } else {
      this.productService.FinalizeInterswitchPayment(ref).subscribe(res => {
        this.loading = false;
        console.log(res);
        transactionData = { ...this.extractInterswitchData(transactionData, res.result) };
        console.log(transactionData);
        this.transactionData$.next(transactionData);
      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      }
    }

  }

  extractInterswitchData(transactionData, apiResponse) {
    const { item1, item2 } = apiResponse;

    transactionData.policies = item2.policies;
    transactionData.printPolicyUrl = item2.printPolicyUrl;
    transactionData.totalAmount = item2.totalAmount;
    transactionData.wef = item2.wef;
    transactionData.wet = item2.wet;
    transactionData.cardNumber = item1.cardNumber;
    transactionData.paymentReference = item1.paymentReference;

    return transactionData;
  }

  finalizeRavePayment(transactionRef, product, ispolicyrenewal, chargeResponseCode): any {
    this.loading = true;
    this.error = false;

    let transactionData = new TransactionModel();
    transactionData.txnref = transactionRef;
    transactionData.productType = product;

    if (chargeResponseCode == "00" || chargeResponseCode == "0") {
      transactionData.gatewayResponse = "Approved Successful";
    } else {
      transactionData.gatewayResponse = "Transaction Failed";
    }

    if (ispolicyrenewal) {
      this.productService.FinalizeFlutterwavePolicyPayment(transactionRef).subscribe(res => {
        this.loading = false;
        console.log(res);

        transactionData = { ...this.extractRaveData(transactionData, res.result) };
        this.transactionData$.next(transactionData);
        this.loading = false;

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      }
    } else {
      this.productService.FinalizeFlutterwavePayment(transactionRef).subscribe(res => {
        this.loading = false;
        console.log(res);

        transactionData = { ...this.extractRaveData(transactionData, res.result) };
        this.transactionData$.next(transactionData);
        this.loading = false;

      }), error => {
        this.errorStatus$.next(`${error.status}`);
        this.errorMessage$.next(error.statusText);
        this.error = true;
        this.loading = false;
      }
    }
  }

  extractRaveData(transactionData, apiResponse) {
    const { item1, item2 } = apiResponse;

    transactionData.policies = item2.policies;
    transactionData.printPolicyUrl = item2.printPolicyUrl;
    transactionData.totalAmount = item2.totalAmount;
    transactionData.wef = item2.wef;
    transactionData.wet = item2.wet;
    transactionData.cardNumber = item1.data.card.cardBIN;
    transactionData.paymentReference = item1.data.flw_ref;

    return transactionData;
  }

  joinArr(arr) {
    if (arr) {
      return arr.join(', ');
    }

    return 'N/A';
  }

  formatCurrency(value) {
    const num = parseFloat(value);

    if (num === num) {
      return `\u20A6${value}`;
    }

    return value;
  }

}
